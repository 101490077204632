// Login, Register, Reset Password

.login-container{ min-height: 100vh; padding: 6.125rem 0; display: flex; align-items: center; background: var(--bg-color); background-size: cover;}
.login-card{ border: 0.0625rem solid var(--border-color); border-radius: 1rem; padding: 1.875rem; background: var(--white); backdrop-filter: blur(0.5rem);}

.label{ display: block; padding-bottom: 0.625rem; font-weight: 500;}
.input{ width: 100%; border: 0.0625rem solid var(--border-color); border-radius: 0.5rem; color: var(--font-color); padding: 0.9375rem 1.125rem; transition: all 0.5s ease;}
.input:focus{ border: 0.0625rem solid var(--link);}

.login-card input{ background: var(--white);}
.login-left .logo{ max-width: 15.625rem;}

.input.fill{ background: #F6F6F6;}


.login-container{ min-height: calc(100vh - 3.75rem);}

.login-container .container{ max-width: 81.25rem !important;}
.login-header{ position: fixed; top: 16px; left: 0; width: 100%; padding: 0 1.875rem; height: 3.75rem; display: flex; align-items: center;}
.login-logo img{ max-height: 4.5rem;}

.login-left h3{font-size: 44px;}


@media (max-width: 1350px) { 
    .login-container .container{ max-width: 65.25rem !important;}
}


@media (max-width: 1023px) {
    .login-left{ padding-bottom: 50px; text-align: center; max-width: 600px; margin: 0 auto;}
    .login-container{ padding: 0 30px;}
    .login-container{ min-height: calc(100vh - 18.75rem);} 
}

@media (max-width: 991px) {
    .login-container{ padding: 0 10px;}
    .login-header{ position: static; justify-content: center; height: auto; padding: 20px 0;}
    .login-container{ padding: 30px 0;}
    .login-logo img{ max-height: 45px;}
}

@media(max-width: 767px){
    .login-left h3{font-size: 36px;}
}