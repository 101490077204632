/* .content-video-container */


::-webkit-scrollbar-track {
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

.content-video-container .cont_area {
    width: 100%;
}

.content-video-container .cont-box {
    /* background: var(--white);
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important; */
    /* padding: 30px; */
    /* position: relative;
    margin-top: 0; */
}

.content-video-container .page-container {
    padding-top: 0;
    /* padding-inline: 30px; */
}

.content-video-container .projectTitle-wrap {
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1019607843);
    display: flex;
    padding: 20px 35px;
    position: sticky;
    top: 3.75rem;
    width: 100%;
    z-index: 3;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
    flex-wrap: wrap;
}

.content-video-container .projectTitle {
    width: 100%;
    color: var(--font-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-video-container .projectTitle-left h2 {
    font-size: 1.25rem;
    font-weight: 600;
}

/* .content-video-container .projectTitle-left h2 span {
    font-size: 1.625rem;
    font-weight: 500;
} */

.tabSearch.content-video-tabSearch {
    position: relative;
    border: none;
}

.tabSearch.content-video-tabSearch input {
    border: 1px solid var(--link);
    background: var(--white);
    border-radius: 50px;
    padding: 13px 40px 13px 45px !important;
}


.content-video-container .custom-search {
    border-radius: 50px !important;
    padding: 13px 40px 13px 45px;
    /* border: 1px solid var(--link); */
}

.content-video-container .tabSearch input[type=number]

/* .tabSearch input[type=text]  */
    {
    background: #080815;
    border: 0;
    border-radius: 8px;
    color: #fff;
    width: 100%;
}

.content-video-container .tabSearch .input {
    padding-top: 7px;
    padding-bottom: 7px;
}

.content-video-container .inp-icon {
    color: var(--color-5);
    left: .9375rem;
    position: absolute;
    top: .5625rem;
    top: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.content-video-container .demoLink {
    align-items: center;
    /* background: #32cd32; */
    background: var(--button-1);
    box-shadow: inset 0 .25rem .25rem 0 #ffffff40, 0 .25rem .25rem 0 #00000040;
    display: inline-flex;
    gap: 0 .5rem;
    justify-content: center;
    border: none;
    border-radius: 3.125rem;
    color: #fff;
}

.content-video-container .videoProject-single {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
}

.content-video-container .videoProject-single-1 {
    aspect-ratio: 16 / 9;
    border-radius: .9375rem;
    width: 100%;
}

.content-video-container .videoProject-single-top-1 {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-radius: 0.9375rem;
    overflow: hidden;
}

.content-video-container .videoProject-single-top-2 {
    height: 400px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-radius: 0.9375rem;
    overflow: hidden;
}

.content-video-container .video-card-wrapper-1 {
    /* background: linear-gradient(190deg, #e512c9, #5d00ff); */
    background: #080815;
    cursor: pointer;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.video-card-wrapper-1 img {
    width: 100%;
    height: auto;
}

.content-video-container .video-card-content,
.video-card-content-2 {
    height: 100%;
    width: 100%;
}

.content-video-container .video-card-content {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-height: 100%;
    background: var(--border-color);
}

.content-video-container .vidThumb-img {
    width: 3.75rem;
}

.content-video-container .qz-tag {
    background: linear-gradient(180deg, #117899, #062833);
    border-radius: .75rem;
    color: #fff;
    display: inline-block;
    font-size: .75rem;
    font-weight: 600;
    left: .75rem;
    padding: .25rem .8125rem;
    position: absolute;
    top: .75rem;
    z-index: 1;
}

.content-video-container .vidHover {
    align-items: center;
    background: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0 1.875rem;
    position: absolute;
    top: 100%;
    transition: all .5s ease;
    visibility: hidden;
    width: 100%;
    z-index: 2;
}

.content-video-container .vidHover p {
    color: #fff;
    font-size: .875rem;
}

.content-video-container .vidiconAll {
    align-items: center;
    display: flex;
    padding-top: 1.5625rem;
}

.content-video-container .vidHover-single {
    cursor: pointer;
    margin: 0 .3125rem;
}

.content-video-container .vidHover-single a {
    color: #fff;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
}

.content-video-container .vidHover-icon {
    background: #303343;
    border-radius: 100%;
    color: #bec0c9;
    font-size: 1.375rem;
    height: 3.125rem;
    /* line-height: 3.25rem; */
    margin: 0 auto;
    width: 3.125rem;
    justify-content: center;
    align-items: center;
}

.content-video-container .vidHover-single a:hover .vidHover-icon {
    color: var(--link)
}

.content-video-container .vidHover-single span {
    display: flex;
    text-align: center;
}

.content-video-container .vidHover-single span+span {
    font-size: .8125rem;
    padding: .5rem 0 0;
}

.content-video-container .videoProject-single-bottom {
    color: #bec0c9;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    padding: 1.25rem 0 .5rem;
    align-items: center;
}

.content-video-container .videoProject-single-bottom h6 {
    color: var(--font-color);
    font-size: 1rem;
    font-weight: 600;
}

.content-video-container .videoProject-single:hover .vidHover {
    top: 0;
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.5);
}

.content-video-container .videoProject-single-top::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.content-video-container .videoProject-single:hover .videoProject-single-top::after {
    opacity: 1;
    visibility: visible;
}

.content-video-editor .campaignBar {
    justify-content: flex-end;
    background: var(--white) !important;
    position: sticky;
    top: 53px;
    z-index: 9;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1019607843);
    display: flex;
    padding: 20px 35px;
    width: 100%;
    z-index: 3;
    justify-content: space-between;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
    flex-wrap: wrap;
}

.content-video-editor .campaignBar .demoLink {
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: inline-flex;
    align-items: center;
    font-size: 0.9375rem;
    margin-left: 0.625rem;
}

.content-video-editor .campaignBar .demoLink img {
    margin-right: 0.625rem;
}

/* .content-video-editor .campaignBar.for-editor {
    height: 4.375rem;
    padding-top: 0;
    padding-bottom: 0;
} */

.content-video-editor .content-video-editor .pageTitle {
    font-size: 1.625rem;
    font-weight: 600;
}

.content-video-editor .pageTitle.main-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.content-video-editor .gradientTxt span {
    display: inline-block;
    background: var(--bg-color);
    color: transparent;
    background-clip: text;
    text-transform: capitalize;
}

.content-video-editor .font-block .gradientTxt span {
    color: var(--font-color);
}

.content-video-editor .demoLink {
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    padding: 0.875rem 1.875rem;
    border-radius: 3.125rem;
    margin-top: 1.875rem;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    background: var(--button-1);
}

.content-video-editor .demoLink:focus {
    border: none;
    box-shadow: none;
    outline: 0;
}

.content-video-editor .demoLink .fa {
    margin-right: 0.625rem;
}

.content-video-editor .site-link .fa {
    margin-right: 0.625rem;
}

.content-video-editor .demoLink:hover {
    color: #FFF;
}

.content-video-editor .demoLink:hover {
    opacity: 0.7;
}

.content-video-editor .editorWrap {
    padding: 30px;
}

.content-video-editor .editor-left {
    background: var(--bg-color);
    height: calc(100vh - 22rem);
    width: 27.9375rem;
    position: fixed;
    left: 1.875rem;
    top: 145px;
    color: #FFF;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.25rem;
    z-index: 999;
    border-radius: 1.875rem;
    border: 0.0625rem solid var(--border-color) !important;
    scrollbar-color: var(--color-3) #f6f6f6;
    scrollbar-width: thin;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .swatch-cover {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.content-video-editor .editor-left p {
    font-size: 13px;
    font-weight: 500;
}

.content-video-editor .editor-left::-webkit-scrollbar {
    width: 0.375rem;
}

.content-video-editor .editor-left::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 0.625rem;
}

/* .content-video-editor .react-draggable {
    border: 2px dashed var(--color-3);
    padding: 0.1875rem;
} */

.content-video-editor .story-block ul li {
    margin-top: 0.9375rem;
}

.content-video-editor .story-block ul li:first-child {
    margin-top: 0;
}

.content-video-editor .story-block-single {
    background: var(--white);
    /* border: 0.0625rem solid #080815; */
    border-radius: 0.75rem;
    padding: 1.25rem;
    color: var(--color-1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .story-block ul li:hover .story-block-single,
.content-video-editor .story-block ul li.active .story-block-single {
    background: var(--color-3);
}

.content-video-editor .story-block ul li:hover .story-block-single,
.content-video-editor .story-block ul li.active .story-block-single {
    background: var(--color-3);
}

.content-video-editor .editor-right {
    width: 100%;
    padding: 0 0 10rem 29.5625rem;
}

.editor-background-img_9x16 {
    height: 100% !important;
    object-fit: cover;
    width: 100%;
    margin: 0 auto;
    object-position: center;
}


.content-video-editor .campaignNav {
    /* background: var(--bg-color); */
    padding: 0.75rem;
    border: none !important;
    border-radius: 1.25rem;
    box-shadow: none !important;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important; */
}

.content-video-editor .campaignNav .nav {
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

/* .content-video-editor .campaignNav .nav-item {
    flex: 1;
} */

.content-video-editor .campaignNav .nav-link {
    width: auto;
    min-width: 100px;
    max-width: 110px;
    height: 4rem;
    background: var(--white);
    border-radius: 0.5625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color) !important;
    margin: 0;
    cursor: pointer;
    padding: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843);
}

.content-video-editor .campaignNav .nav-link.active {
    background: var(--color-3);
}

.content-video-editor .campaignNav .nav-link span {
    display: block;
    width: 100%;
    text-align: center;
    padding: 3px 0;
}

.content-video-editor .campaignNav .nav-link span img {
    height: auto;
    max-height: 1.8rem;
    max-width: 1.5rem;
    filter: invert(1);
}

.content-video-editor .campaignNav .nav-link span+span {
    font-size: 0.8125rem;
    padding-top: 0.125rem;
}

.content-video-editor .canvasEditor {
    max-width: 40rem;
    margin: 0 auto;
    padding-top: 1.5625rem;
}

.content-video-editor .canvasEditor-main_16x9 {
    position: relative;
    width: 640px;
    height: 360px;
    overflow: hidden;
    /* padding-top: 56.25%; */
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
    -webkit-box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    background: #000000b5;
}

.content-video-editor .videoControll_16x9 {
    height: 3.75rem;
    padding: 0 1.5625rem;
    background: var(--color-5);
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    color: #FFF;
    width: 640px;
}

.content-video-editor .videoControll_9x16 {
    height: 3.75rem;
    padding: 0 1.5625rem;
    background: var(--color-5);
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    color: #FFF;
    width: 360px;
}

.content-video-editor .canvasEditor-main_9x16 {
    position: relative;
    width: 360px;
    height: 640px;
    overflow: hidden;
    /* padding-top: 56.25%; */
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
    -webkit-box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.16);
    background: #000000b5;
}


.content-video-editor .canvasEditor-main iframe,
.content-video-editor .canvasEditor-main canvas,
.content-video-editor .canvasEditor-main video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.content-video-editor .controls {
    padding: 0;
    margin-bottom: 1.5625rem;
    border-radius: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 1.5625rem;
}

.content-video-editor .control-btn a {
    display: block;
    transition: all 0.5s ease;
}

.content-video-editor .control-btn a img {
    max-width: 1.875rem;
    max-height: 1.4375rem;
    filter: invert(1);
}

.content-video-editor .control-btn a:hover {
    opacity: 0.5;
}

.cursor-pointer {
    cursor: pointer;
}

.content-video-editor .control-btn a .whiteFont {
    color: var(--primary-color);
    font-size: 20px;
}

.content-video-editor .videoControll-txt {
    height: 3.75rem;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
}

.content-video-editor .videoControll-txt span {
    font-size: 0.875rem;
}

.content-video-editor .videoControll-txt span+span {
    margin-left: 0.9375rem;
}

.content-video-editor .videoControll-nav ul {
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-video-editor .videoControll-nav ul li {
    margin: 0 0.3125rem;
    font-size: 1.375rem;
    cursor: pointer;
}

.content-video-editor .videoControll-nav ul li .fa-play {
    margin-left: 0.1875rem;
}

.content-video-editor .videoControll-nav ul li:hover {
    color: var(--primary-color);
}

.content-video-editor .videoControll-right {
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.125rem;
}

.content-video-editor .videoControll-right span {
    font-size: 14px;
}

.content-video-editorReel.editorReel {
    /* background-color: #000; */
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    background: none;
}

.content-video-editorReel .editorReelwrap {
    /* border-radius: 1.25rem; */
    background: var(--bg-color);
    padding: 1.25rem;
    padding-bottom: 9px;
    border: 0.0625rem solid var(--border-color) !important;
}

.content-video-editorReel .editorReel-scroll::-webkit-scrollbar {
    height: 0.3125rem;
}

.content-video-editorReel .editorReel-scroll::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 0.625rem;
}

.content-video-editorReel .editorReel-scroll {
    align-items: center;
    display: flex;
    overflow-x: auto !important;
    overflow-y: hidden;
    padding-bottom: 9px;
    scrollbar-color: var(--button-1) #f6f6f6;
    scrollbar-width: thin;
}

.content-video-editorReel .editorReel-scroll ul {
    display: flex;
    white-space: nowrap;
    position: relative;
}

.content-video-editorReel .editorReel-scroll ul li:first-child {
    margin-left: 0;
}

.content-video-editorReel .editorReel-single {
    width: 100%;
    height: 100%;
}

.content-video-editorReel .editorReel-single img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.content-video-editorReel .editorReel-dragmessage {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
    text-align: center;
}

.content-video-editorReel .editorReel-scroll ul li .Sub-editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    width: 150px;
    height: 75px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.content-video-editorReel .editorReel-single .delIcon {
    position: absolute;
    right: 0.1875rem;
    top: 0.25rem;
    color: '#fff';
    font-size: 1.25rem;
}

.content-video-editorReel .editor_main_tiles {
    background: var(--bg-color);
    padding: 10px 50px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: end;
    margin: 0px 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editorReel .editor_main_tiles .editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    width: 175px;
    height: 95px;
    background: #1a1c24;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color-3) !important;
}

.content-video-editorReel .active-tile-border {
    border: 2px solid var(--color-3);
}

.content-video-editorReel .IconDel {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 27px;
    height: 27px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c3450;
    cursor: pointer;
}

.content-video-editorReel .micON {
    position: absolute;
    top: 4px;
    left: 4px;
}

.content-video-editorReel .transition-wrapper {
    display: flex;
    align-items: center;
}

.content-video-editorReel .deactive-tile-border {
    border: 1px solid #5b5858
}

.content-video-editorReel .addReelBt {
    position: absolute;
    right: -3.125rem;
    top: 50%;
    margin-top: -1.0625rem;
    cursor: pointer;
}

.content-video-editor .textarea-block-wrap {
    background: var(--white);
    border-radius: 0.625rem;
    padding: 0.9375rem;
    margin-top: 1.875rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .textarea-block-wrap .form-control {
    background-color: var(--white) !important;
    color: var(--font-color) !important;
    border: 0.0625rem solid #707070;
}

.content-video-editor .textarea-block-wrap .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.content-video-editor .textarea-block-wrap h2 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--font-color);
}

.content-video-editor .textarea-btn {
    border-radius: 0.3125rem !important;
    padding: 0.375rem 0.5625rem !important;
    font-size: 0.875rem;
    color: #fff;
    font-weight: 500;
    border: none;
    background: var(--button-1);
}

.content-video-editor .txt-format h2 {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 16px;
}

.content-video-editor .fontStyle-block {
    margin-top: 1rem;
}

.content-video-editor .txt-format select {
    width: 100%;
    color: var(--font-color);
    font-size: 0.8125rem;
    padding: 0.5rem 0.3125rem;
    background: var(--white) !important;
    border: 0.0625rem solid #2d303c !important;
    border-radius: 0.5rem !important;
}

.content-video-editor .edit-input-font-size {
    color: var(--font-color) !important;
    height: auto;
    box-shadow: none;
    resize: none;
    width: 100%;
    height: 2.3125rem !important;
    letter-spacing: 0.0625rem;
    padding-left: 0.5rem;
    font-size: 0.8125rem;
    outline: 0rem !important;
    background: var(--white) !important;
    border: 0.0625rem solid #2d303c !important;
    border-radius: 0.5rem !important;
}

.content-video-editor .font-format {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    font-size: 1.125rem;
    background: var(--white) !important;
    border: 0.0625rem solid #2d303c !important;
    border-radius: 0.5rem !important;
    color: var(--font-color);
}

.content-video-editor .font-active {
    border: .0625rem solid var(--link) !important;
}

.content-video-editor .font-format .fa {
    font-size: 1rem;
}

.content-video-editor .edit-input-lineheight {
    color: var(--font-color) !important;
    box-shadow: none;
    resize: none;
    width: 100%;
    height: 2.5625rem !important;
    letter-spacing: 0.0625rem;
    padding-left: 0.5rem;
    font-size: 0.8125rem;
    outline: 0.0625rem !important;
    background: var(--white) !important;
    border: 0.0625rem solid #2d303c !important;
    border-radius: 0.5rem !important;
}

.content-video-editor .fontStyle-block-wrap {
    background: var(--white);
    border-radius: 0.625rem;
    padding: 0.9375rem;
    margin-top: 1.875rem;
    color: var(--font-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .inpWrap {
    position: relative;
}

.content-video-editor .txt-format input[type="text"] {
    width: 100%;
    color: var(--font-color);
    font-size: 0.8125rem;
    padding: 0.5rem 0.3125rem;
    background: var(--white) !important;
    border: .0625rem solid #2d303c !important;
    border-radius: .5rem !important;
}

.content-video-editor .colorValue {
    position: absolute;
    left: 0.375rem;
    top: 0.3125rem;
    width: 1.6875rem;
    height: 1.6875rem;
    background: #FFF;
    border-radius: 0.3125rem;
    border: 1px solid var(--primary-color);
}

.content-video-editor span.label {
    display: block;
    text-align: center;
    color: #7D7D7D;
    font-size: 0.75rem;
    font-weight: 600;
    padding-top: 0.3125rem;
}

.content-video-editor .color-palette ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.0625rem;
    margin-left: -0.0625rem;
}

.content-video-editor .color-palette ul li {
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 0.0625rem;
    padding-left: 0.0625rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 9.09%;
    flex: 0 0 9.09%;
    max-width: 9.09%;
    margin-top: 0.125rem;
}

.content-video-editor .color-palette-single {
    height: 1.875rem;
    background: #009DB0;
    border-radius: 0.125rem;
    border: 1px solid var(--primary-color);
}

.content-video-editor .fontStyle-block-wrap .itemDisp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
}

.content-video-editor .switch {
    position: relative;
    display: inline-block;
    width: 3.125rem;
    height: 1.875rem;
    margin: 0 !important;
    padding: 0 !important;
}

.content-video-editor .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.content-video-editor .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #636363;
    -webkit-transition: .4s;
    transition: .4s;
}

.content-video-editor .slider:before {
    position: absolute;
    content: "";
    height: 1.4375rem;
    width: 1.4375rem;
    left: 0.25rem;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.content-video-editor input:checked+.slider {
    background-color: var(--link);
}

.content-video-editor input:focus+.slider {
    box-shadow: 0 0 0.0625rem #C23636;
}

.content-video-editor input:checked+.slider:before {
    -webkit-transform: translateX(1.1875rem);
    -ms-transform: translateX(1.1875rem);
    transform: translateX(1.1875rem);
}

.content-video-editor .sketch-picker {
    position: absolute;
    z-index: 1;
}

.content-video-editor .inTabNav.nav {
    flex-wrap: nowrap;
    background: var(--white);
    border-radius: 0.625rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .inTabNav.nav .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    border-left: 0.0625rem solid #1A1A1A;
}

.content-video-editor .inTabNav.nav .nav-item:first-child {
    border-left: 0;
}

.content-video-editor .inTabNav.nav .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--font-color) !important;
    font-size: 0.8125rem;
    padding: 0.9375rem 0.3125rem;
    text-align: center;
    height: 100%;
    border-radius: 0 !important;
}

.content-video-editor .inTabNav.nav .nav-link.active {
    color: #FFF !important;
    background: var(--link) !important;
}

.content-video-editor .innertab {
    padding-top: 1.25rem;
}

.content-video-editor .inp-icon {
    color: var(--color-5);
    left: .9375rem;
    position: absolute;
    top: .5625rem;
    top: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.content-video-editor .innertab-scroll {
    margin-top: 20px;
    /* height: calc(100vh - 23.125rem); */
    height: auto;
    max-height: 435px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
}

.content-video-editor #scrollableAudio123 {
    max-height: 500px;
}

.content-video-editor .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden !important;
}

.content-video-editor .media-ul-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.5rem;
}

.content-video-editor .mediaList h6 {
    color: var(--font-color);
}

.content-video-editor .media-ul-list li {
    margin-top: 0.125rem;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 0.0625rem;
    padding-top: 0.0625rem;
    padding-bottom: 0.0625rem;
    padding-left: 0.0625rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    color: var(--font-color);
}

.content-video-editor .media-ul-list li:first-child,
.content-video-editor .media-ul-list li:nth-child(2) {
    margin-top: 0;
}

.content-video-editor .myImage-section {
    position: relative;
}

/* unknown */
.hover-loader-center {
    position: absolute;
    top: 30%;
    left: 42%;
    transform: translate(-50%, -50%);
    color: var(--primary);
    font-size: 1.875rem;
}

.content-video-editor .mediaList-single {
    height: 6.875rem;
    border: 0.0625rem solid #707070;
    border-radius: 0.625rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
}

.content-video-editor .myImage-respo img {
    /* width: 100%; */
    height: 100%;
}

.content-video-editor .mediaImg {
    object-fit: cover;
    height: 100%;
}

.content-video-editor .slide-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 0.1875rem solid var(--link); */
    padding: 5px;
    border-radius: 50%;
}

.content-video-editor .slide-active i {
    color: var(--link);
    transform: scale(1.5);
}

.content-video-editor .crop-icon {
    position: absolute;
    top: 0.3125rem;
    left: 1.0625rem;
    width: auto !important;
    height: auto !important;
    z-index: 2;
    color: var(--link);
    cursor: pointer;
}

.content-video-editor .mediaList-single video {
    height: 100%;
}

.content-video-editor .sketch-picker-head h4 {
    color: var(--font-color);
    padding: 0.625rem 0;
    margin-left: 1.75rem;
    font-size: 0.9375rem;
}

.content-video-editor .sketch-picker-wrapper {
    border-radius: 0.625rem;
    padding-bottom: 1.875rem;
    padding-top: .9375rem;
    width: 100%;
    position: relative;
    z-index: 1;
    background: var(--white);
}

.content-video-editor .flexbox-fix {
    justify-content: center !important;
}

.content-video-editor .flexbox-fix label {
    font-size: 0.75rem;
    font-weight: 600;
    color: #000;
}

.content-video-editor .sketch-picker.color-picker-style .flexbox-fix:last-child div {
    width: 3.125rem !important;
    height: 2.5rem !important;
}

.content-video-editor .sketch-picker.color-picker-style {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 80% !important;
    max-width: 280px !important;
}

.content-video-editor #left-tabs-example-tabpane-color-tab .innertab-scroll {
    max-height: 100%;
}

.content-video-editor .absInp {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    cursor: pointer;
    /* display: none; */
}

.content-video-editor .tabSearch textarea {
    background: var(--white);
    color: var(--font-color);
    width: 100%;
    border: 0;
    padding: 6px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor textarea.inp-small {
    height: 150px;
    padding-top: 20px;
    font-size: 13px;
}

.content-video-editor .mediaList ul label {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
}

.content-video-editor .delete-media {
    position: absolute;
    top: 0.375rem;
    right: 0.3125rem;
    z-index: 1;
    color: #CE88FF;
    filter: drop-shadow(0.125rem 0.25rem 0.375rem black);
    width: unset !important;
    height: unset !important;
}

.content-video-editor .delete-media svg {
    /* position: absolute; */
    right: 0.3125rem;
    top: 0.3125rem;
    font-size: 16px;
    color: var(--white);
}

.content-video-editor .delete-media i {
    color: #d20424;
}

.content-video-editor .select-tile-style {
    font-size: 28px;
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 8px;
    width: 100%;
    z-index: 1;
    color: var(--link);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-video-editor .ai-voice-block {
    background: var(--white);
    border-radius: 0.625rem;
    padding: 0.625rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .voice-block-top select,
.content-video-editor .voice-block-top input[type="text"] {
    width: 100%;
    background: var(--white);
    color: var(--font-color);
    font-size: 0.8125rem;
    padding: 0.5rem 0.3125rem;
    border: .0625rem solid #2d303c !important;
    border-radius: .5rem !important;
}

.content-video-editor .optDesc {
    display: block;
    text-align: center;
    font-size: 0.6875rem;
    color: var(--font-color);
    padding-top: 0.3125rem;
}

.content-video-editor .voice-block-mid {
    padding-bottom: .625rem;
    padding-top: 1.25rem;
}

.content-video-editor .voice-block-mid textarea {
    width: 100%;
    height: 15.625rem;
    font-size: 0.875rem;
    color: var(--font-color);
    padding: 0.9375rem;
    background: var(--white) !important;
    border: 0.0625rem solid #2d303c !important;
    border-radius: 0.5rem !important;
    scrollbar-width: none;
}

.content-video-editor .audioList-single {
    border-radius: 0.625rem;
    overflow: hidden;
    background: -moz-linear-gradient(top, #32cd32 0%, #141414 100%);
    background: -webkit-linear-gradient(top, #32cd32 0%, #141414 100%);
    background: linear-gradient(to bottom, #32cd32 0%, #141414 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a00ff', endColorstr='#4d0080', GradientType=0);
}

.content-video-editor .audioList-single-music li .audioList-single {
    margin-top: 0.375rem !important;
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
    margin-bottom: 0.375rem !important;

}

.content-video-editor .active-audio .active-audio-wrapper {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000b3;
    z-index: 1;
    border-radius: 0.4375rem;
    border: 0.125rem solid
}

.content-video-editor .active-audio .active-audio-wrapper i {
    color: var(--link);
}

.content-video-editor .active-audio-wrapper {
    display: none;
}

.content-video-editor .audioList-top {
    position: relative;
    height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-video-editor .card-icon-delete {
    position: absolute;
    top: 0.1875rem;
    cursor: pointer;
    color: #fff;
    left: 0.3125rem;
}

.content-video-editor .waveIcon {
    max-width: 4.6875rem;
}

.content-video-editor .audio-time {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    font-size: 0.6875rem;
    font-weight: 500;
}

.content-video-editor .audioList-bottom {
    height: 2.5rem;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    padding: 0 0.9375rem;
}

.content-video-editor .audioList-bottom .fa {
    margin-right: 0.5rem;
    font-size: 1.25rem;
    color: var(--white);
}

.content-video-editor .audioList-bottom p {
    line-height: 0.875rem;
}

.content-video-editor .audioList-bottom .fa-play-circle {
    color: var(--white);
}

.content-video-editor .voice-record-time span {
    font-size: 4.0625rem;
}

.content-video-editor .record-img {
    align-items: center;
    background: #fff;
    display: flex;
    height: 3.125rem;
    justify-content: center;
    margin: 0 auto;
    width: 3.125rem;
}

.content-video-editor .recording-wrapper li {
    margin-bottom: .625rem;
    padding-right: 0;
}

.content-video-editor .sad_talker_wrap .tabTitle {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-color);
}

.content-video-editor .opt-select {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
}

.content-video-editor .font-block h6 {
    color: var(--primary-color);
}

.content-video-editor .tabSearch input[type=number] {
    background: var(--white);
    border: 0;
    border-radius: 8px;
    color: var(--font-color);
    width: 100%;
    padding: 6px 13px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .add-block-single {
    margin-top: 1.875rem;
}

.content-video-editor .add-block-single h2 {
    color: var(--primary-color);
}

.content-video-editor .add-block-single:first-child {
    margin-top: 0;
}

.content-video-editor .add-block-main {
    background: var(--white);
    border-radius: 0.625rem;
    padding: 0.9375rem;
    margin-top: 0.9375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .add-block-main h3 {
    font-size: 0.875rem;
    font-weight: 600;
    padding-bottom: 0.625rem;
}

.content-video-editor .add-block-main .inPut {
    width: 100%;
    color: #000;
    font-size: 0.8125rem;
    padding: 0.5rem 0.3125rem;
    background: #fff !important;
    border: 0.0625rem solid #2d303c !important;
    border-radius: 0.5rem !important;
}

.content-video-editor .motionlist ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -0.3125rem;
    margin-left: -0.3125rem;
}

.content-video-editor .motionlist ul li {
    min-height: 0.0625rem;
    padding-right: 0.4375rem;
    padding-left: 0.3125rem;
}

.content-video-editor .transition-single {
    position: relative;
    height: 7.375rem;
    cursor: pointer;
    margin: 0.3125rem 0;
    border-radius: 0.625rem;
    overflow: hidden;
}

.content-video-editor .transition-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-video-editor .transition-txt {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-video-editor .add-block-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: var(--font-color);
}

.content-video-editor .fileUpload,
.content-video-editor .upload {
    align-items: center;
    background: var(--white);
    border: 1px dashed var(--link);
    border-radius: .625rem;
    display: flex;
    font-size: .875rem;
    height: 5.8125rem;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 10px;
    color: #000;
}

.content-video-editor .fileUpload .fa,
.content-video-editor .upload .fa {
    font-size: 1.25rem;
    margin-right: .625rem;
}

.content-video-editor .fileUpload input[type=file],
.content-video-editor .upload input[type=file] {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    cursor: pointer;
}

.content-video-editor .itemDisp {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: .75rem;
}

.content-video-editor .itemDisp-left ul {
    margin: 0;
    padding: 0;
}

.content-video-editor .itemDisp-left ul li {
    background: #171717;
    border: .0625rem solid #686868;
    border-radius: 100%;
    display: flex;
    height: 3.5rem;
    margin-left: .625rem;
    vertical-align: top;
    width: 3.5rem;
    align-items: center;
    justify-content: center;
}

.content-video-editor .itemDisp-left ul li:first-child {
    margin-left: 0;
}

.content-video-editor .uploadImg {
    border-radius: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.content-video-editor .itemDisp-right select {
    background: #fff;
    border: none;
    border-radius: .3125rem;
    color: #000;
    font-size: .875rem;
    padding: .5rem .3125rem;
    width: 12.5rem;
    border: 1px solid #080815;
}

.content-video-editor .add-block-single h2 {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 500;
}

.content-video-editor .slidern {
    -webkit-appearance: none;
    background: var(--bg-color);
    border-radius: .625rem;
    height: .5rem;
    outline: none;
    width: 100%;
}

.content-video-editor .sad_talker_img {
    display: flex;
    justify-content: center;
    width: 100%;
}

.content-video-editor .sad_talker_img img {
    border: 1px dashed var(--link);
    border-radius: 100%;
    height: 120px;
    width: 120px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
    object-fit: cover;
}

.content-video-editor .form-check {
    font-size: .875rem;
    color: var(--primary-color);
}

.content-video-editor .form-check-input:checked {
    background-color: var(--link);
    border-color: var(--link);
}

.content-video-editor h2.tabTitle {
    font-size: 1.5625rem;
    padding-bottom: 1.25rem;
}

.content-video-editor .position-single {
    background: var(--white);
    border: .125rem solid #080815;
    border-radius: .625rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
}

.content-video-editor .position-single.active {
    border: .125rem solid var(--link);
}

.content-video-editor .position-circle {
    background: var(--white);
    border: .125rem solid #080815;
    border-radius: .625rem;
    border-radius: 100%;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
}

.content-video-editor .position-circle.active,
.content-video-editor .position-square.active {
    border: .125rem solid var(--link);
}

.content-video-editor .position-square {
    background: var(--white);
    border: .125rem solid #080815;
    border-radius: .625rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
}

.content-video-editor .face_size {
    align-items: center;
    background: var(--white);
    border-radius: 3.125rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: .5rem !important;
    padding-top: .5rem !important;
    color: #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .face_size span {
    cursor: pointer;
    font-weight: 600;
}

.content-video-editor .face_size span.active {
    color: var(--link);
}


.content-video-container_wrap {
    padding-left: 18.625rem;
    padding-right: 0;
    width: 100%;
}

.content-video-editor .voice-block-mid {
    padding-top: 1.25rem;
    padding-bottom: 0.625rem;
}

.content-video-editor .voice-block-mid textarea {
    background: var(--white);
    border: 0.0625rem solid #707070;
    border-radius: 0.75rem;
    width: 100%;
    height: 15.625rem;
    font-size: 0.875rem;
    color: var(--font-color);
    padding: 0.9375rem;
}

.content-video-editor .modal-block {
    background: var(--white);
    border-radius: .9375rem;
    padding: 1.5625rem;
    color: var(--font-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

.content-video-editor .file-field {
    align-items: center;
    border: .0625rem dashed #495162;
    border-radius: .9375rem;
    display: flex;
    flex-direction: column;
    gap: .3125rem;
    justify-content: center;
    padding: .9375rem;
    position: relative;
    text-align: center;
}

.content-video-editor .embed-user-icon {
    background: var(--link);
    border: 0.0625rem solid var(--link);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 100%;
}

.content-video-editor .embed-user-icon svg {
    font-size: 11px;
    color: var(--white);
}

.content-video-editor .embed-user-icon.fill svg {
    font-size: 20px;
}

.content-video-editor .file-field input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
}

.content-video-editor .or-style {
    position: relative;
    text-align: center;
}

.content-video-editor .or-style span {
    align-items: center;
    background: #141414;
    border: .0625rem solid #141414;
    border-radius: 3.125rem;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    padding: .3125rem 1.875rem;
    position: relative;
    z-index: 2;
}

.content-video-editor .or-style::after {
    background: #3f424e;
    content: "";
    height: .0625rem;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
}

.content-video-editor .inpt.large {
    padding: 0.9375rem;
}

.content-video-editor .inpt {
    background: var(--white);
    border: none;
    border-radius: .5rem;
    color: var(--font-color);
    font-size: .75rem;
    padding: .375rem .8125rem;
    border: 1px solid #080815;
}

.content-video-editor .themeTable tr td {
    vertical-align: middle;
    color: var(--font-color);
    font-size: 0.8125rem;
    border-bottom: 0.0625rem solid #2a2e36 !important;
    border-top: none !important;
    padding-left: 5px;
}

.content-video-editor .themeTable tr th {
    vertical-align: middle;
    color: var(--font-color);
    font-size: 0.8125rem;
    border: none !important;
    background: var(--bg-color);
    padding: 12px 4px;
}

.content-video-editor .themeTable h6 {
    font-size: 0.8125rem;
}

.content-video-editor .themeTable .embed-user-icon {
    padding: 0 !important;
    width: 1.5625rem;
    height: 1.5625rem;
    border: 0.0625rem solid #3f424e !important;
    margin: 0 0.1875rem;
}

.content-video-editor .voiceover-recording-wrap .innertab-scroll {
    height: auto;
}

.content-video-editor .table-responsive {
    scrollbar-width: thin;
}

.content-video-container .fa-spinner,
.content-video-editor .fa-spinner {
    color: var(--link);
}

.content-video-container .site-link .fa-spinner,
.content-video-editor .site-link .fa-spinner {
    color: var(--white);
}

.content-video-editor .loader-center-temp1 {
    position: absolute;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .convertVideoModal .question-type-single{
    display: flex;
    gap: 10px;
} */

.convertVideoModal .common-input-wrap {
    background: var(--color-2);
    border-radius: .9375rem;
    padding: 1.5625rem;
}

.convertVideoModal .common-input-wrap .modal-block {
    background: var(--color-2);
    border-radius: .9375rem;
    padding: 1.5625rem;
}

.convertVideoModal .question-type ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem;
}

.convertVideoModal .question-type ul li {
    margin-top: 1rem;
    padding: 0 .5rem;
    width: 33.33%;
}

.convertVideoModal .question-type-single {
    border: .0625rem solid var(--primary-color);
    border-radius: .625rem;
    cursor: pointer;
    height: 100%;
    padding: .9375rem;
    position: relative;
}

.convertVideoModal .question-type-single input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.convertVideoModal .question-type-single .col-auto {
    padding: 0;
}

.convertVideoModal .radio-style {
    align-items: center;
    border: .0625rem solid #2c2c2c;
    border-radius: 100%;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    position: relative;
    width: 1.5rem;
}

.convertVideoModal .question-type-single:has(input:checked) .radio-style {
    background: #32cd32;
    border: none;
}

.convertVideoModal .question-type-single:has(input:checked) .radio-style:after {
    background: #fff;
    border-radius: 100%;
    content: "";
    height: .75rem;
    width: .75rem;
}

.convertVideoModal .question-type-single h6 {
    color: var(--font-color);
    font-size: 1rem;
    font-weight: 400;
}

.convertVideoModal .question-type-single .col {
    padding: 0 0 0 .9375rem;
}

.convertVideoModal .add-block-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.convertVideoModal .switch {
    display: inline-block;
    height: 1.875rem;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    width: 3.125rem;
}

.convertVideoModal .switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

.convertVideoModal input[type=checkbox] {
    accent-color: #32cd32;
    accent-color: var(--primary);
}

.convertVideoModal .slider {
    -webkit-appearance: none;
    background: #fff;
    background: #cfcfcf;
    border-radius: 10px;
    opacity: .7;
    outline: none;
    transition: opacity .2s;
    width: 100%;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 2.125rem;
}

.convertVideoModal .modal hr {
    background-color: #2a2e3a !important;
    opacity: 1 !important;
}

.convertVideoModal .slider:before {
    background-color: #fff;
    bottom: .23rem;
    content: "";
    height: 1.4375rem;
    left: .25rem;
    width: 1.4375rem;
    position: absolute;
    transition: .4s;
    border-radius: 50%;
}

.convertVideoModal .slider.round:before {
    border-radius: 50%;
}

.convertVideoModal .fileUpload,
.convertVideoModal .upload {
    align-items: center;
    background: var(--white);
    border: 1px dashed #707070;
    border-radius: .625rem;
    display: flex;
    font-size: .875rem;
    height: 5.8125rem;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 100%;
}

.convertVideoModal .fileUpload .fa,
.convertVideoModal .upload .fa {
    font-size: 1.25rem;
    margin-right: .625rem;
}

.convertVideoModal .fileUpload input[type=file],
.convertVideoModal .upload input[type=file] {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

.convertVideoModal .cursor-pointer {
    cursor: pointer;
}

.convertVideoModal input[type=checkbox] {
    accent-color: #32cd32;
}

.convertVideoModal .file-field {
    align-items: center;
    border: .0625rem dashed #495162;
    border-radius: .9375rem;
    display: flex;
    flex-direction: column;
    gap: .3125rem;
    justify-content: center;
    padding: .9375rem;
    position: relative;
    text-align: center;
}

.convertVideoModal .embed-user-icon {
    align-items: center;
    background: #32cd32;
    border: .0625rem solid #32cd32;
    border-radius: 100%;
    display: inline-flex;
    height: 3.125rem;
    justify-content: center;
    width: 3.125rem;
}

.convertVideoModal .embed-user-icon svg {
    color: var(--white);
    font-size: 11px;
}

.convertVideoModal .embed-user-icon.fill svg {
    color: var(--white);
    font-size: 20px;
}

.convertVideoModal .file-field input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
}

.convertVideoModal .site-link.small {
    padding: .625rem 1rem;
}

.convertVideoModal .or-style {
    position: relative;
    text-align: center;
}

.convertVideoModal .or-style span {
    align-items: center;
    background: var(--white);
    border: .0625rem solid #141414;
    border-radius: 3.125rem;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    padding: .3125rem 1.875rem;
    position: relative;
    z-index: 2;
}

.convertVideoModal .or-style:after {
    background: #3f424e;
    content: "";
    height: .0625rem;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
}

.convertVideoModal .inpt {
    background: var(--white);
    /* border: none; */
    border-radius: .5rem;
    color: var(--font-color);
    font-size: 14px;
    padding: .375rem .8125rem;
}

.convertVideoModal .inpt.large {
    padding: .9375rem;
}

.convertVideoModal .themeTable tr td {
    vertical-align: middle;
    color: #FFFFFF;
    font-size: 0.8125rem;
    border-bottom: 0.0625rem solid #2a2e36 !important;
    border-top: none !important;
}

.convertVideoModal .themeTable tr td .block-white {
    background-color: transparent;
}

.convertVideoModal .themeTable tr th {
    vertical-align: middle;
    color: #FFFFFF;
    font-size: 0.8125rem;
    border: none !important;
    background: #141321;
    padding: 12px 6px;
}

.convertVideoModal .themeTable h6 {
    font-size: 0.8125rem;
}

.convertVideoModal .themeTable .embed-user-icon {
    padding: 0 !important;
    width: 1.5625rem;
    height: 1.5625rem;
    border: 0.0625rem solid #3f424e !important;
    margin: 0 0.1875rem;
}

.convertVideoModal .site-link span {
    font-size: 14px;
}

.convertVideoModal .modal-dialog {
    margin-top: 50px !important;
}

.content-video-container .cont-box .inp-bg-top p {
    background: var(--link);
    border-radius: 20px 20px 0 0;
    color: var(--white);
    font-size: 15px;
    padding: 20px;
}

.content-video-container .cont-box .inp-bg-mid textarea {
    padding: 20px;
}

.content-video-editor .media-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

/* videomodal */

/* .VideoModal .modal-dialog{
    margin: auto;
    padding: 0 10px;
} */

.content-video-editor .error-not-found {
    color: var(--font-color);
}

.content-video-editor .font-weight-bold {
    font-weight: bold;
}

.content-video-editor .font-italic {
    font-style: italic;
}


.instructions-modal .Face_narration {
    text-align: center;
}

.instructions-modal .Face_narration_section {
    height: 100%;
}

.instructions-modal .Face_narration_section h2 {
    font-size: 1.625rem;
}

.instructions-modal .Face_narration_section span {
    font-size: 1.625rem;
    border-bottom: 0.0625rem solid var(--border-color);
    display: inline-block;
    position: relative;
    top: 0.3125rem;
}

.instructions-modal .Face_narration_section .Thumb img {
    width: 2.5rem;
    transform: rotateY(180deg);
}

.instructions-modal .Face_narration_section .Thumbs img {
    width: 2.5rem;
    transform: rotateY(180deg) rotateX(180deg);
}

.instructions-modal .Face_narration_do,
.instructions-modal .Face_narration_dont {
    padding: 0.625rem;
    height: 100%;
}

.instructions-modal .Face_narration_do li,
.instructions-modal .Face_narration_dont li {
    justify-content: flex-start;
    text-align: left !important;
    display: inline-flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    margin-bottom: 0.625rem;
}

.instructions-modal .Face_narration_do li span,
.instructions-modal .Face_narration_dont li span {
    font-size: 0.75rem;
    color: var(--font-color);
    border: none;
    position: static;
}

.instructions-modal .Face_narration_do li .green,
.instructions-modal .Face_narration_dont li .green,
.instructions-modal .Face_narration_do li .red,
.instructions-modal .Face_narration_dont li .red {
    color: #fff;
    font-size: 1.1875rem;
    width: 1.5625rem;
    padding: 0.375rem;
    height: 1.5625rem;
    border-radius: 100%;
}

.instructions-modal .Face_narration_do li .green,
.instructions-modal .Face_narration_dont li .green {
    background: green;
}

.instructions-modal .Face_narration_do li .red,
.instructions-modal .Face_narration_dont li .red {
    background: red;
}


.instructions-modal .Face_narration_do .Thumb,
.instructions-modal .Face_narration_dont .Thumbs {
    margin-bottom: 1.875rem;
}


.instructions-modal .Face_narration_img {
    width: 9.375rem;
    height: 9.375rem;
    overflow: hidden;
    margin-top: 1.25rem;
}

.instructions-modal .Face_narration_img img {
    width: 100%;
    height: 100%;
    border-radius: 0.9375rem;
}

.instructions-modal .Face_narration_do,
.instructions-modal .Face_narration_dont {
    border: 0.0625rem solid var(--border-color);
    border-radius: 0.9375rem;
    padding: 1.875rem;
}

.instructions-modal .modal-dialog {
    margin-top: 50px;
}


/* watermark */

.content-video-editor .watermark-op {
    opacity: .5;
}

.content-video-editor .logo-top-left {
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 0.625rem;
}

.content-video-editor .logo-top-right {
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 0.625rem;
}

.content-video-editor .logo-bottom-right {
    position: absolute !important;
    bottom: 0;
    right: 0;
    padding: 0.625rem;
}

.content-video-editor .logo-bottom-left {
    position: absolute !important;
    bottom: 0;
    left: 0;
    padding: 0.625rem;
}

.content-video-editor .logo-top-center {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.625rem;
}

.content-video-editor .logo-center-left {
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 0.625rem;
}

.content-video-editor .logo-center {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.625rem;
}

.content-video-editor .logo-center-right {
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0.625rem;
}

.content-video-editor .logo-bottom-center {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.625rem;
}

.content-video-editor .logo-style {
    max-width: 6.25rem;
    z-index: 2;
}

#my-tooltip-37 {
    left: 0 !important;
}





.loader {
    width: 5rem;
    height: 5rem;
    border: 0.125rem var(--link) solid;
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

.loader-sec {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader::after {
    width: 0.625rem;
    height: 0.625rem;
    background-color: var(--link);
    border-radius: 100%;
    position: absolute;
    content: "";
}

.content-video-container .projectTitle-left h2 a {
    color: var(--font-color);
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.site-link-disabled {
    background: #808080;
    cursor: not-allowed;
    border-radius: 0.5rem;
    color: var(--white);
    border: none;
    padding: 0.55rem 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    font-weight: 600;
}

.site-link-disabled.small {
    font-size: 0.75rem;
}


.layer-border {
    border: 2px dashed #18bd15;
    padding: 0.1875rem;
    transition: none;
}




@media(max-width:1600px) {
    .content-video-editor .editor-left {
        height: calc(100vh - 24rem);
    }
}

@media(max-width:1200px) {
    .content-video-editor .editor-left {
        width: 95%;
        position: unset;
        max-height: calc(100vh - 24rem);
        height: auto;
        margin-top: 135px;
        margin-bottom: 24px;
        margin-inline: auto;
    }

    .content-video-editor .editor-right {
        padding: 0;
        padding-bottom: 11rem;
    }

    .content-video-editor .editor-right .campaignTop {
        position: absolute;
        top: 135px;
    }

}

@media(max-width:1199px) {
    .content-video-container .page-container {
        padding-inline: 30px;
    }
}

@media(max-width:767px) {

    /* .video-editor-head-wrap .site-header{
        height: 3.75rem;
    } */
    .content-video-editor .editor-left {
        width: 100%;
    }

    .content-video-editor .campaignBar {
        top: 118px;
    }

    .content-video-editor .editor-right .campaignTop {
        top: 205px;
    }

    .content-video-editor .editor-left {
        margin-top: 242px;
    }

    .content-video-container .white_label_marketing_wrap {
        margin-top: 30px;
    }

}

@media(max-width:733px) {
    .content-video-editor .campaignNav .nav {
        margin-top: 15px;
    }

    .content-video-editor .campaignNav .nav-link {
        min-width: 60px;
    }

    .content-video-editor .videoControll_16x9,
    .content-video-editor .canvasEditor-main_16x9,
    .content-video-editor .canvasEditor-main_9x16,
    .content-video-editor .videoControll_9x16 {
        /* position: absolute; */
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%) scale(0.8);
    }

    .content-video-editor .videoControll_16x9 {
        position: relative;
        top: -42px;
    }

    .content-video-editor .videoControll_9x16 {
        position: relative;
        top: -68px;
    }
}

@media(max-width:550px) {

    .content-video-editor .videoControll_16x9,
    .content-video-editor .canvasEditor-main_16x9 {
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%) scale(0.6);
    }

    .content-video-editor .videoControll_16x9 {
        top: -83px;
    }

    .content-video-editor .canvasEditor {
        margin-bottom: 80px;
    }

    .content-video-editor .videoControll_16x9,
    .content-video-editor .canvasEditor-main_16x9 {
        top: -80px;
        left: 50%;
        transform: translate(-50%, 0%) scale(0.5);
    }

    .content-video-editor .videoControll_16x9 {
        top: -184px;
    }

    .content-video-editor .editor-right {
        padding: 0;
        padding-bottom: 0;
    }
}

@media(max-width:498px) {
    .content-video-editor .editor-left {
        margin-top: 310px;
    }
}

@media(max-width:480px) {
    .content-video-editor .editor-left {
        margin-top: 300px;
    }
}