@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.kap_nav {
    font-weight: 500;
    color: rgba(30, 30, 30, 0.35);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    width: 100%;
    background: none;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
}

.kap_nav_submenu {
    font-weight: 500;
    color: rgba(30, 30, 30, 0.35);
    border-radius: 0.5rem;
    border: none;
    width: 100%;
    background: none;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
}

.kap_nav_submenu.active{
    background:var(--link);
}

.kap_nav_submenu.active span {
    color: var(--white);
}

.page-wrap.expand .m_kap {
    display: none;
}

.page-wrap.expand .side-link ul .kap_nav {
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-wrap.expand .m_dir {
    display: none;
}

.kap_nav svg {
    font-size: 1.375rem;
}

.kap_nav span {
    display: flex;
    align-items: center;
    gap: 10px
}

.kap_nav.active {
    background: var(--link);
}

.kap_nav.active span {
    color: var(--white);
}

.kap_nav.active svg {
    color: var(--white);
}

.content_submenu {
    background: #FFF;
    padding: 0.625rem;
    border-radius: 0.625rem;
}

.input-wrap.with-icon .inp {
    padding-left: 70px;
}

.input-wrap.with-icon {
    position: relative;
}

.input-wrap.with-icon input::placeholder {
    color: #838383;
    font-size: 14px;
    font-family: "Inter", serif;
    font-weight: 400;
}

.video-table tr th {
    font-weight: 600;
    padding: 20px;
    font-size: 15px;
    letter-spacing: 0.6px;
    font-family: "Inter", serif;
}

.video-table tr th:not(.video-table tr th:first-child){
    min-width: 205px;
}
.video-table tr th:last-child{
    min-width: unset !important;
}

.inp {
    background: var(--white);
    border: 1px solid #e7e7e7;
    border-radius: 50px;
    padding: 18px 35px;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    font-family: "Inter", serif;
}

.cont-box,
.cont-box-group {
    margin-top: 30px;
}

.cont-box {
    /* background: var(--white); */
    border-radius: .5rem;
    /* box-shadow: 0 4px 6px #0000001a !important; */
    /* padding: 60px; */
    position: relative;
}

.inp-icon {
    color: var(--color-5);
    left: 35px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.tablepage ul {
    padding: 20px 0 0;
    text-align: center;
}

.tablepage ul li {
    display: inline-block;
    margin: 0 3px;
    vertical-align: top;
}

.tablepage svg {
    color: var(--white) !important;
}

.tablepage ul li a {
    align-items: center;
    background: var(--color-5);
    border-radius: 6px;
    color: var(--white);
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 30px;
    justify-content: center;
    width: 30px;
}

.table_active {
    background: var(--color-3) !important;
}

.video-table tr td {
    /* background: #fff !important; */
    border-top: 1px solid var(--border-color);
    color: #838383;
    font-size: 14px;
    padding: 10px 20px;
    font-family: "Inter", serif;
    font-weight: 500;
}

.modalClose {
    color: var(--white);
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: -8px;
    top: -46px;
}

.project_modal .modal-dialog {
    align-items: center;
    display: flex;
    height: 92%;
    padding-inline: 25px;
}

.common-input-wrap {
    margin-top: 30px;
}

.common-input-wrap label {
    display: block;
    padding-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    font-family: "Inter", serif;
}

.project_modal .modal-body {
    padding: 30px;
}

.common-input-wrap input::placeholder {
    font-size: 13px;
    font-weight: 500;
    font-family: "Inter", serif;
}


.project_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create_content_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.create_content_top .common-input-wrap {
    flex: 1;
    margin-top: 0;
}

.create_content_head {
    margin-top: 25px;
}

.create_content_head h4 {
    color: var(--black);
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: "Inter", serif;
}

.create_content_middle {
    margin-top: 35px;
}

.break_line {
    border: none;
    height: 3px;
    opacity: 1;
    background: var(--color-1);
}

.question-container {
    margin: 10px 0;
}

.question-label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--black);
    font-family: "Inter", serif;
}

.prompt_wrapper h5 {
    color: var(--black);
    font-size: 15px;
    margin-bottom: 15px;
    font-family: "Inter", serif;
}



.generate-content p {
    font-weight: bold;
    color: var(--black);
    font-size: 16px;
}

.create_content_top h5 {
    width: 100%;
    font-weight: 700;
    font-size: 17px;
    border-radius: 16px;
    padding: 5px 8px !important;
    font-family: "Inter", serif;
}

.create_content_top label {
    padding-left: 7px;
    font-size: 17px;
    font-weight: 700;
    color: var(--link);
    display: inline-block;
    font-family: "Inter", serif;
}

.common_wrapper_campaign {
    margin-bottom: 45px;
}

.kap .common-input {
    background: var(--white);
    border: 1px solid #e9e9e9;
    border-radius: 16px;
    padding: 17px 15px !important;
    width: 100%;
    font-family: "Inter", serif;
}

.kap textarea.common-input {
    height: 162px;
}

.kap .generate-content {
    border: 1px dotted var(--link);
    border-radius: 16px;
    padding: 15px;
}



.site-link.disabled::before {
    display: none;
}

.loadernew {
    width: 80px;
    height: 80px;
    border: 3px solid var(--link);
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

.loader_space {
    padding-left: 20.625rem;
}

.loader-sec-new {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loadernew::after {
    width: 15px;
    height: 15px;
    background: radial-gradient(66.67% 103.95% at 50% -42.76%, #889e88 0%, var(--link) 100%), rgba(255, 255, 255, 0.05);
    border-radius: 100%;
    position: absolute;
    content: "";
}

.campaigns-data-table table th span {
    background: none;
}

.campaigns-data-table .table>:not(caption)>*>* {
    background: none;
}

@media screen and (max-width:1199px) {
    .loader_space {
        padding-left: 0px;
    }
}


@media screen and (max-width:992px) {
    .video-table tr th {
        white-space: nowrap;
    }

    .cont-box {
        padding: 15px;
    }

    .create_content_top h5 {
        white-space: nowrap;
    }
}

@media screen and (max-width:767px) {
    .mob-margin {
        margin: 60px 10px 20px 10px;
    }
}

@media screen and (max-width:576px) {
    .create_content_top label {
        white-space: nowrap;
    }

    .cont-box,
    .cont-box-group {
        margin-top: 20px;
    }

    .cont-box {
        padding: 20px;
    }

    .create_content_top {
        gap: 15px;
        flex-wrap: wrap;
    }

    .create_content_top label,
    .common-input-wrap h5 {
        font-size: 14px;
        padding-left: 0px !important;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}