// Common Module

.box-color {
  background: var(--primary-color);
  border: 0.0625rem solid var(--border-color)
}

.dashboard-block,
.tabepaneIn-cont {
  background: var(--white);
  border: 0.0625rem solid var(--border-color);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-top: 1.5625rem;
}

.upgrade-box-content,
.upload-box-content {
  background: #f7f7f7;
  border-radius: 10px;
  padding: 15px;
}

.account-wrap {
  display: flex;
  align-items: flex-start;
  max-width: 65rem;
  margin: 0 auto;
  color: var(--font-color);
}

.account-left {
  width: 15rem;
}

.account-right {
  width: calc(100% - 15rem);
  padding-left: 3.25rem;
}

// .account-right.large {
//   width: 48.75rem;
// }

.account-right.larger {
  width: 48.75rem;
}

.account-nav ul li {
  margin-top: 0;
}

.account-nav ul li:first-child {
  margin-top: 0;
}

.account-nav ul li a {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.6875rem 0.625rem;
  color: var(--font-color);
  font-weight: 500;
  border-radius: 0.625rem;
  font-size: 0.95rem;
}

.account-nav ul li a.active {
  background: var(--link);
  color: var(--white);
}

.account-nav ul li a svg {
  font-size: 1.375rem;
  margin-right: 0.625rem;
}


.account-nav ul li.has-subMenu a {
  border-radius: 0 !important;
}

.whiteMenu {
  border-radius: 0.625rem;
  overflow: hidden;
  margin-top: 1.5625rem;
  padding-bottom: 0.9375rem;
}

.whiteMenu ul li a {
  padding-top: 0;
  padding-bottom: 0;
  background: none !important;
}

.whiteMenu li a.active {
  background: none !important;
}

.whiteMenu ul {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.subMenu {
  padding-top: 0.625rem;
}

.subMenu ul li a {
  font-size: 0.8125rem;
  padding: 0.4375rem 0.5625rem;
}

.subMenu ul li {
  margin: 0 !important;
}

.subMenu ul li a.active {
  background: #cdcdcd !important;
}

.subMenu ul li a svg {
  font-size: 1.125rem;
}

.whiteMenu:has(a.active) {
  background: var(--charcoal);
}

.account-title {
  font-size: 0.9375rem;
  font-weight: 600;
  opacity: .8;
  text-transform: uppercase;
}

.titleBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.titleBar-left h2 {
  font-size: 1.375rem;
  font-weight: 500;
}

.titleBar-left p {
  opacity: .7;
}


.info-img {
  position: relative;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: 1.875rem;
}

.info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.account-pic-upload {
  align-items: center;
  background: var(--link);
  border: 0.125rem solid var(--white);
  border-radius: 100%;
  bottom: 0;
  color: #fff;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 2.5rem;
}

.account-pic-upload input {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.data-block {
  padding: 1.25rem 0;
}

.data-block p {
  font-size: 0.875rem;
  opacity: 0.7;
}

.modal-backdrop {
  // -webkit-backdrop-filter: blur(0.625rem);
  // backdrop-filter: blur(0.625rem);
  // background: var(--bg-color) !important;
  // opacity: 1 !important;
  background: none !important;
}

.modal {
  background: #c0c0c0bd !important;
  backdrop-filter: blur(0.625rem);
}

.VideoModal .modal-dialog {
  max-width: 62.5rem;
}

.sm-modal .modal-dialog {
  max-width: 31.25rem !important;
}

.sm-modal .modal-body {
  padding: 1.25rem;
}

.VideoModal .modal-content {
  background: var(--white) !important;
  border: 0.0625rem solid var(--border-color) !important;
  border-radius: 0.9375rem;
}

.modalIcon {
  align-items: center;
  background: var(--bg-color);
  border-radius: 100%;
  display: inline-flex;
  font-size: 2rem;
  height: 6.25rem;
  justify-content: center;
  margin: 0 auto;
  width: 6.25rem;
}

.btnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 0.75rem;
}

.integrations-single {
  // border-bottom: 0.0625rem solid hsla(0, 0%, 100%, .14);
  // padding: 1.25rem 0;
  flex-direction: column;
}

.integrations-in+.integrations-in {
  border-top: 0.0625rem solid var(--border-color);
  margin: 0.625rem 0 0;
  padding: 0.625rem 0 0;
}

.integrations-single:first-child {
  padding-top: 0;
}

.integrations-in {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.integration-left,
.integrations-single {
  align-items: center;
  display: flex;
}

.integrations-single h6 {
  font-size: 0.875rem;
  font-weight: 600;
}

.integration-txt {
  padding-left: 0.625rem;
}

.integrations-single p {
  font-size: 0.8125rem;
  opacity: .7;
  padding: 0;
}

.integration-img {
  height: 2.625rem;
  width: 2.625rem;
}

.integration-img img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}


.manage-block-single {
  background: var(--white);
  border-radius: 0.75rem;
  justify-content: space-between;
  margin-top: 1.25rem;
  padding: 1rem 1.125rem;
  border: 0.0625rem solid var(--border-color);
}

.manage-block-left,
.manage-block-single {
  align-items: center;
  display: flex;
}

.manage-block-icon {
  align-items: center;
  background: var(--bg-color);
  border-radius: 100%;
  display: inline-flex;
  font-size: 1.375rem;
  height: 2.625rem;
  justify-content: center;
  width: 2.625rem;
}

.manage-block-txt {
  padding-left: 0.625rem;
}

.manage-block-single h6 {
  font-size: 0.9375rem;
  font-weight: 600;
}

.manage-block-single p {
  font-size: 0.875rem;
  opacity: .7;
}

.group-icon {
  font-size: 1.375rem;
}

.group-icon svg {
  font-size: 1.5rem;
  width: 20px;
  height: 20px;
}

.group-icon span {
  cursor: pointer;
  display: inline-block;
  margin-left: 0.3125rem;
  opacity: .7;
}

.modalClose {
  cursor: pointer;
}

.titleBar-left h6 {
  color: var(--white);
}

.upgrade-nav .nav {
  display: block;
}

.upgrade-nav .nav-link {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.6875rem 1.375rem;
  color: var(--font-color);
  font-weight: 500;
  border-radius: 0.625rem;
  border: 0.0625rem solid transparent;
  font-size: 0.8125rem;
}

.upgrade-nav .nav-link.active {
  background: var(--link);
  color: var(--white);
}

.upgrade-nav .nav-item {
  margin-bottom: 0.3125rem;
}

.support-single {
  align-items: center;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.5625rem;
  min-height: 11.25rem;
  padding: 0.9375rem;
  text-align: center;
  transition: all 0.5s ease;
  background: var(--white);
  border: 0.0625rem solid var(--border-color);
}

.support-single:hover {
  border: 0.0625rem solid var(--link);
}

.support-icon {
  align-items: center;
  background: var(--link);
  border-radius: 100%;
  display: flex;
  font-size: 1.25rem;
  height: 3rem;
  justify-content: center;
  width: 3rem;
}

.support-single p {
  font-size: 0.8125rem;
  padding: 0.75rem 0 0 0;
  color: var(--font-color);
}

.support-icon img {
  max-width: 1.25rem;
}

.support-vid-single {
  background: var(--white);
  border: 0.0625rem solid var(--border-color);
  border-radius: 0.75rem;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
}

.support-txt {
  padding: 0.9375rem;
  font-size: 0.875rem;
}

.modalVidWrap {
  border-radius: 0.9375rem;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.modalVid iframe {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.vidClose {
  cursor: pointer;
  font-size: 1.875rem;
  position: absolute;
  right: 0;
  top: -2.5rem;
  color: #ef1564;
}

.img-block img {
  width: 100%;
  border-radius: 0.625rem;
}

.account-wrap.for-post .account-left {
  width: 21.875rem;
}

.account-wrap.for-post .account-right {
  margin: 0;
  padding-left: 3.125rem;
  width: calc(100% - 21.875rem);
}


// 
.VideoModal.large .modal-dialog {
  max-width: 70rem;
}

.VideoModal.md-modal .modal-dialog {
  max-width: 43.75rem;
}

.btn-disable {
  background: gray !important
}

.btn-disable:hover {
  background: gray !important
}

.btn-disable:hover::before {
  background: gray !important
}

.kap_url {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  padding-block: 10px;
}

.kap_play {
  width: 32px;
  height: 32px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

}

.kap_play svg {
  font-size: 16px;
}

.youtubeicon {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  width: 100%;
  height: 100%;

  i {
    font-size: 45px;
  }
}

// 

@media (max-width: 1199px) {
  .account-right {
    padding: 0;
  }
}

@media (max-width: 1023px) {
  .account-wrap {
    flex-wrap: wrap;
  }

  .account-left {
    width: 100%;
    padding-bottom: 40px;
  }

  .account-right {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .account-nav ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .account-nav ul li {
    margin: 0 !important;
  }

  .account-nav ul li a {
    border: 1px solid var(--border-color);
  }

  .input {
    padding: 12px 15px;
  }

  .account-right.large {
    width: 100%;
  }

  .upgrade-nav .nav-link {
    border: 1px solid var(--border-color);
    padding: 9px 12px;
  }

  .account-wrap.for-post .account-left {
    width: 100%;
  }

  .upgrade-nav .nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .account-wrap.for-post .account-right {
    padding: 0;
    width: unset;
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .titleBar {
    flex-wrap: wrap;
  }

  .titleBar-left {
    width: 100%;
    padding-bottom: 8px;
  }

  .account-wrap {
    padding: 15px;
    padding-top: 50px;
  }

  .account-nav ul li {
    width: 100%;
  }
}

// Common Module