 .site-hero-wrap .projectTitle {
     align-items: center;
     color: var(--white);
     display: flex;
     justify-content: space-between;
 }

 .site-hero-wrap .projectTitle-wrap {
     align-items: center;
     border-bottom: 1px solid var(--white)fff1a;
     display: flex;
     padding: 20px 35px;
     position: sticky;
     top: 3.75rem;
     width: 100%;
     z-index: 3;
     justify-content: space-between;
     background-color: var(--white);
     margin-bottom: 25px;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
     flex-wrap: wrap;
 }

 .site-tab-search {
     position: relative;
     border: 1px solid var(--link);
     border-radius: 100%;
     border-radius: 100px;
 }

 .site-hero-wrap .inp-icon {
     color: var(--color-5);
     left: 35px;
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
 }

 .site-tab-search .inp-icon {
     left: 15px;
 }

 .site-tab-search input {
     background: transparent;
     border: 0;
     border-radius: 0;
     padding: 13px 40px 13px 45px;
 }

 .site-hero-wrap .project-input-in {
     border-radius: 3.125rem;
     padding: 1px;
     position: relative;
 }

 .site-hero-wrap .project-input-in span {
     align-items: center;
     color: #c3c3c3;
     display: flex;
     justify-content: center;
     position: absolute;
     right: .75rem;
     top: 50%;
     transform: translateY(-50%);
 }

 .site-hero-wrap .project-input-in .inp {
     border-radius: 8px;
     padding: 10px 33px;
 }

 .site-link svg {
     position: relative;
 }

 .white-videoProject-single {
     background: #000;
     border: 1px solid #707070;
     padding: .625rem;
     background: none;
     border: none;
     border-radius: .9375rem;
     margin-bottom: 1.3rem;
     margin-top: 0px;
 }

 .inpBtn-icon {
     align-items: center;
     background: var(--link);
     border-bottom-right-radius: .625rem;
     border-top-right-radius: .625rem;
     color: var(--white);
     display: flex;
     font-weight: 500;
     height: 4.375rem;
     justify-content: center;
     padding: 0 1.875rem;
     position: absolute;
     right: 0;
     top: 0;
 }

 .white-videoProject-single .videoProject-single-top {
     border-radius: .5rem;
     overflow: hidden;
 }

 .white-videoProject-single .videoProject-single-top {
     min-height: 14.0625rem;
     height: 14.375rem;
     position: relative;
 }

 .site-common-inp::placeholder {
     font-size: 14px;
 }

 select.site-common-inp {
     font-size: 14px;
 }

 .white-videoProject-single .video-card-wrapper-1 {
     cursor: pointer;
     height: 100%;
     max-width: 100%;
     overflow: hidden;
     width: 100%;
 }

 .white-videoProject-single .video-card-content img {
     height: 60px;
     width: 150px;
     margin-block: 0 !important;
     object-fit: contain;
 }

 .site-common-inp:focus,
 .color-wrap:focus,
 .inpLabelWrap .inpLabel-inp:focus,
 .inp:focus {
     border: 1px solid var(--link);
 }

 .modal-body .site-common-inp {
     padding: 0.9375rem 1.125rem;
     background: unset;
 }

 .modal-cross {
     top: -46px;
     right: -8px;
 }

 .no-campaign-card-box h5 {
     font-weight: 500;
 }

 .white-videoProject-single .video-card-content {
     flex-flow: column;
     justify-content: center;
     max-height: 100%;
     align-items: center;
     display: flex;
     height: 100%;
     width: 100%;
     background: var(--border-color);
 }

 .white-videoProject-single .videoProject-single-bottom {
     color: #bec0c9;
     font-size: 1rem;
     font-weight: 600;
     align-items: center;
     display: flex;
     color: var(--font-color);
     padding: 1.25rem 0 .5rem;
 }

 .white-videoProject-single .videoProject-single-bottom p {
     color: #0D0C14;
     font-size: .8125rem;
     font-weight: 500;
     padding-top: .25rem;
 }

 .white-videoProject-single .qz-tag {
     border-radius: .5rem;
     color: var(--white);
     display: inline-block;
     font-size: .8rem;
     left: .75rem;
     padding: .25rem .8125rem;
     position: absolute;
     top: .75rem;
     z-index: 1;
 }


 .formSec label {
     margin-bottom: .5rem;
     font-size: 14px;
     font-weight: 500;
 }

 .white-videoProject-single .vidHover p {
     color: var(--white);
     font-size: .875rem;
 }

 .white-videoProject-single .vidiconAll {
     align-items: center;
     display: flex;
     padding-top: 1.5625rem;
 }

 .white-videoProject-single .vidHover-single {
     align-items: center;
     cursor: pointer;
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     gap: 4px;
 }

 .white-videoProject-single .vidHover-single a {
     color: var(--white);
     display: inline-block;
     margin-bottom: 5px;
     margin-right: 16px;
     text-align: center;
 }

 .white-videoProject-single .vidHover-icon {
     background: var(--white);
     color: #141414;
     font-size: 1.1rem;
     height: 36px;
     line-height: 3.25rem;
     margin: 0 auto;
     width: 36px;
     border-radius: 50px;
 }

 .white-videoProject-single .vidHover-single span+span {
     font-size: .750rem;
     padding: .5rem 0 0;
 }

 .white-videoProject-single .vidHover-single span {
     align-items: center;
     display: block;
     display: flex;
     justify-content: center;
     text-align: center;
 }

 .white-videoProject-single .vidHover {
     align-items: center;
     background-color: #000c;
     color: var(--white);
     display: flex;
     flex-direction: column;
     height: 100%;
     justify-content: center;
     left: 0;
     opacity: 0;
     padding: 0 1.875rem;
     position: absolute;
     top: 0;
     transition: all .5s ease;
     visibility: hidden;
     width: 100%;
 }



 .videoProject-single:hover .vidHover {
     opacity: 1;
     visibility: visible;
     background: #000000a8;
     z-index: 0;
 }

 .videoProject-single:hover .videoProject-single-top:after {
     opacity: 1;
     visibility: visible;
 }

 .videoProject-single:hover .vidHover {
     top: 0;
 }

 .white-videoProject-single .vidHover {
     background: none;
     z-index: 2;
     top: 100%;
 }

 .projectTitle h4 {
     color: var(--font-color);
 }

 .vidHover-single a:hover .vidHover-icon {
     color: var(--link);
 }

 .vidHover-icon i {
     font-weight: 600;
 }

 .formSec .inpField {
     margin-bottom: 10px;
 }

 .inpField h6 {
     font-weight: 500 !important;
     font-size: 14px;
 }

 .ai_describe_img img {
     height: 100%;
     object-fit: contain;
     width: 100%;
 }

 .ai_describe_img {
     border: 1px dotted #b4b6b9;
     border: 1px dotted var(--border-color);
     border-radius: .75rem;
     /* height: 16.5rem;
     margin: 1.25rem auto 0;
     overflow: hidden;
     width: 70%; */
     height: 14.5rem;
     margin: 1.25rem auto 0;
     overflow: hidden;
     width: 50%;

 }

 .website-builder-ai-btn-loader {
     z-index: 11;
 }

 @keyframes webspin {
     from {
         transform: rotate(0deg);
     }

     to {
         transform: rotate(359deg);
     }
 }

 .web-spin {
     animation: webspin 1000ms linear infinite;
 }

 .modal .inpField-inp {
     border-radius: .5rem;
     width: 100%;
     padding: 10px;
 }

 .inpField-inp {
     background: var(--white);
     border: 1px solid var(--border-color);
     box-shadow: none;
     color: #000;
     padding-left: 1.875rem;
     padding-right: 1.875rem;
 }

 .modal .inpField-inp::placeholder {
     color: #000;
     font-weight: 500;
 }

 .inpBtn,
 .inpField-inp .inpLabelWrap span.labelTxt {
     display: block;
     font-size: .85rem;
     padding-bottom: .3125rem;
     font-weight: 500;
 }

 .VideoModal.small .modal-dialog {
     max-width: 40.25rem !important;
 }

 .content_submenu li a:hover img,
 .content_submenu li a.active img {
     filter: brightness(0) invert(1);
 }



 .inpLabelWrap span.labelTxt {
     display: block;
     font-size: .9375rem;
     padding-bottom: .3125rem;
 }

 .inpLabel {
     position: relative;
     display: flex;
     font-weight: 600;
 }

 .boxStyle {
     background: var(--white);
     border-radius: 8px;
     padding: 1.875rem;
 }

 .inpLabelWrap .inpLabel label {
     font-size: .9rem;
     font-weight: 700;
     left: 0;
     margin: 0;
     padding: .75rem 0 0 1.875rem;
     position: absolute;
     text-align: left;
     top: 0;
     z-index: 1;
 }

 .list-delete {
     position: absolute;
     right: .9375rem;
     top: .9375rem;
 }

 .radius-btn {
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
 }

 .benefit-single {
     background: #f6f6f6;
     padding: 20px;
     border-radius: .5rem;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
 }

 .icons-size i {
     font-size: 16px;
 }

 .inpLabelWrap .inpLabel-inp {
     border: 1px solid var(--white)fff1a;
     /* border-radius: .625rem 0px 0px .625rem !important; */
     font-size: .9rem;
     height: 4.375rem;
     padding: .9375rem 0 0 1.875rem;
     text-overflow: ellipsis;
 }

 .inpLabelWrap .inpLabel-inp {
     background: #f6f6f6;
     border: 1px solid #c7c7c7;
     border-radius: .5rem;
     color: #000;
     font-size: .89rem;
     height: 4.375rem;
     padding: .9375rem 0 0 1.875rem;
     text-overflow: ellipsis;
 }

 .inpLabel-inp {
     background: var(--white);
     border: none;
     border-radius: .3125rem;
     padding: .625rem .9375rem;
     width: 100%;
 }

 /* .inpBtn-icon {
     align-items: center;
     background: #32cd32;
     background: var(--secondary-color);
     border-bottom-right-radius: .625rem;
     border-top-right-radius: .625rem;
     color: var(--white);
     display: flex;
     font-weight: 500;
     height: 4.375rem;
     justify-content: center;
     padding: 0 1.875rem;
     position: absolute;
     right: 0;
     top: 0;
 } */

 .inpLabelWrap span.labelTxt {
     display: block;
     font-size: .9375rem;
     padding-bottom: .3125rem;
 }

 .inpLabelWrap textarea.inpLabel-inp {
     height: 12.5rem;
     padding-top: 2.1875rem;
 }

 .site-common-inp {
     background: #f6f6f6;
     border: 1px solid #c7c7c7;
     border-radius: .5rem;
     padding: 1.1rem 1.875rem;
     width: 100%;
 }

 .inpLabelWrap {
     margin-top: .7375rem;
     margin-bottom: .5rem;
 }

 .Universal_modal .nav {
     background: #141414;
     background: var(--white);
     border-radius: 8px;
     padding: .625rem;
 }

 .Universal_modal .nav-link {
     color: #141414;
 }

 .Universal_url_images {
     border: 1px dotted var(--border-color);
     border-radius: .75rem;
     height: 8.4375rem;
     overflow: hidden;
     width: 100%;
     position: relative;
 }

 .Universal_content_images {
     height: 19.375rem;
     margin-top: 1.25rem;
     overflow-x: hidden;
     overflow-y: auto;
     padding-right: .625rem;
     scrollbar-color: #b4b6b9 #373d49;
     scrollbar-color: var(--link);
     scrollbar-width: thin;
 }

 .delete-media,
 .delete-media svg {
     position: absolute;
     right: .3125rem;
 }

 .delete-media {

     /* filter: drop-shadow(.125rem .25rem .375rem black); */
     height: auto !important;
     top: .375rem;
     width: auto !important;
     z-index: 1;
 }

 .Universal_modal_upload {
     align-items: center;
     border: 1px dotted var(--border-color);
     border-radius: .75rem;
     display: flex;
     height: 10.625rem;
     justify-content: center;
     padding: .625rem;
     position: relative;
     width: 100%;
     margin-bottom: 10px;
 }

 .Universal_modal_upload input {
     bottom: 0;
     cursor: pointer;
     left: 0;
     opacity: 0;
     position: absolute;
     right: 0;
     top: 0;
 }

 .Universal_url_images img {
     cursor: pointer;
     height: 100%;
     object-fit: contain;
     width: 100%;
 }

 .Universal_modal .nav-link.active {
     background: var(--link);
 }

 .fullLink {
     height: 100%;
     right: 0;
     position: absolute;
     top: 0;
     width: 100%;
     z-index: 4;
     border-top-left-radius: 0px;
     border-bottom-left-radius: 0px;
 }

 .Universal_content_wrap h5 {
     color: var(--white) !important;
 }

 .delete-media {
     align-items: center;
     background: var(--link);
     border-radius: .3125rem !important;
     box-shadow: none !important;
     color: var(--white) !important;
     display: flex;
     height: 1.5625rem !important;
     justify-content: center;
     width: 1.5625rem !important;
 }

 .uploaded-img {
     align-items: center;
     background: var(--white);
     border-radius: .625rem;
     display: flex;
     height: 3.75rem;
     justify-content: center;
     left: .3125rem;
     top: .3125rem;
     width: 3.75rem;
     cursor: pointer;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
 }

 .sketch-picker {
     position: absolute;
     z-index: 999;
 }

 .radius-inp {
     border-top-right-radius: 0px !important;
     border-bottom-right-radius: 0px !important;
 }

 .imgStat,
 .uploaded-img.alt {
     position: absolute;
 }

 .uploaded-img img {
     border-radius: .625rem;
     height: 3.75rem;
     object-fit: contain;
     width: 3.75rem;
 }

 .color-wrap {
     align-items: center;
     background: #f6f6f6;
     border: 1px solid var(--border-color);
     border-radius: .5rem;
     color: #000;
     display: flex;
     height: 3.75rem;
     padding: 1.1rem 1.375rem;
     position: relative;
     width: 100%;
 }

 .gjs-mdl-title {
     font-weight: 600;
 }

 .colorBox {
     background: red;
     border: 1px solid gray;
     border-radius: .3125rem;
     cursor: pointer;
     height: 2.5rem;
     position: relative;
     width: 2.5rem;
 }

 .colorVal {
     font-size: 14px;
     padding-left: 5px;
 }

 /* Change the background of the scrollbar */
 .inpLabel-inp::-webkit-scrollbar,
 .landing-items::-webkit-scrollbar {
     width: 4px;
 }

 .inpLabel-inp::-webkit-scrollbar-track,
 .landing-items::-webkit-scrollbar-track {
     background: var(--white);
 }

 .inpLabel-inp::-webkit-scrollbar-thumb,
 .landing-items::-webkit-scrollbar-thumb {
     background: var(--link);
 }

 .inpLabel-inp::-webkit-scrollbar-thumb:hover,
 .landing-items::-webkit-scrollbar-thumb:hover {
     background: var(--link);
 }

 .imgUp .nav-link.active {
     background: var(--link);
     color: var(--white);
 }

 .infinite-scroll-component {
     display: flex;
     flex-wrap: wrap;
     overflow: hidden !important;
 }

 .img-search-scroll ul {
     align-items: center;
     display: flex;
     flex-wrap: wrap;
     margin: 0 -.3125rem;
 }

 .img-search-scroll ul li {
     margin-top: .625rem;
     padding: 0 .3125rem;
     width: 20%;
 }

 .photo-single {
     border: 2px dotted gray;
     border-radius: .5rem;
     overflow: hidden;
     position: relative;
 }

 .photo-single img {
     border-radius: .5rem;
     height: 200px;
     object-fit: contain;
     width: 100%;
 }

 .imgStat span,
 .landing-items {
     align-items: center;
     display: flex;
     justify-content: center;
 }

 .imgStat span {
     background: var(--link);
     border-radius: .3125rem;
     color: var(--white);
     height: 1.875rem;
     width: 1.875rem;
 }

 .imgStat {
     right: 50%;
     top: 50%;
     transform: translate(30%, -50%);
 }

 .style-upload-box {
     align-items: center;
     border: 1px dashed var(--border-color);
     border-radius: .625rem;
     display: flex;
     height: 5.8125rem;
     justify-content: center;
     overflow: hidden;
     position: relative;
 }

 .style-upload-box .custom-file-input {
     background: red;
     cursor: pointer;
     height: 100%;
     opacity: 0;
     position: absolute;
     top: 0;
     width: 100%;
 }

 .style-upload-box h6 {
     color: var(--font-color);
     font-size: 1rem;
     margin-bottom: 0;
 }

 .style-upload-box svg {
     color: var(--font-color);
     font-size: 20px;
 }

 .imgUp .nav-link {
     background: var(--border-color);
     border-radius: .3125rem .3125rem 0 0;
     color: var(--font-color);
 }

 .modalIcon_modal .landing-items {
     height: 560px;
     margin-top: 15px;
     overflow-x: hidden;
     overflow-y: auto;
     padding-bottom: 20px;
     padding-right: 7px;
 }

 .landing-items a {
     align-items: center;
     border: 1px solid var(--border-color);
     border-radius: .3125rem;
     display: inline-flex;
     height: 1.875rem;
     justify-content: center;
     width: 1.875rem;
     color: #141414;
 }

 .landing-items a {
     height: 55px !important;
     width: 55px !important;
 }

 .imgStat span,
 .landing-items {
     align-items: center;
     display: flex;
     justify-content: center;
 }

 .landing-items {
     flex-wrap: wrap;
     gap: .3125rem;
 }

 .landing-items a i {
     font-size: 30px;
 }

 .Choose_Bonus {
     border: 1px solid var(--border-color);
     border-radius: .625rem;
     height: 100%;
     padding: .9375rem;
     position: relative;
 }

 .selected_bonus {
     border: 1px solid var(--link) !important;
 }

 .Choose_Bonus-img img {
     max-height: 10rem;
 }

 .Choose_Bonus-img {
     align-items: center;
     display: flex;
     height: 12.5rem;
     justify-content: center;
     padding: .9375rem;
     width: 100%;
 }

 .site-hero-wrap {
     padding-left: 18.6rem;
 }

 .list_domain {
     list-style: disc !important;
     padding-left: 1rem;
 }

 .tempList ul {
     align-items: center;
     display: flex;
     flex-wrap: wrap;
     margin: 0 -.5rem;
 }

 .tempList ul li {
     margin-top: 1rem;
     padding: 0 .5rem;
     width: 25%;
 }

 .template-img {
     align-items: center;
     border: 1px solid var(--border-color);
     border-radius: 1.25rem;
     display: flex;
     height: 12.5rem;
     justify-content: center;
     overflow: hidden;
     padding: .9375rem;
     position: relative;
 }

 .template-img img {
     max-height: 10rem;
 }

 .message-image-opt {
     align-items: center;
     -webkit-backdrop-filter: blur(.5rem);
     backdrop-filter: blur(.5rem);
     background: #00000080;
     display: flex;
     gap: 0 5px;
     height: 100%;
     justify-content: center;
     left: 0;
     opacity: 0;
     position: absolute;
     top: 0;
     transition: all .5s ease;
     visibility: hidden;
     width: 100%;
     z-index: 5;
 }

 .tempList ul li:hover .message-image-opt {
     opacity: 1;
     visibility: visible;
 }

 .message-image-opt .embed-user-icon {
     height: 41px;
     width: 41px;
 }

 .embed-user-icon {
     align-items: center;
     background: var(--link);
     border-radius: 100%;
     display: inline-flex;
     height: 3.125rem;
     justify-content: center;
     width: 3.125rem;
     color: var(--white);
 }

 .select-tile-style {
     align-items: center;
     border: .125rem solid #32cd32;
     border: .125rem solid var(--link);
     border-radius: 1.25rem !important;
     color: var(--link);
     display: flex;
     font-size: 1.75rem;
     height: 100%;
     justify-content: center;
     position: absolute;
     top: 0;
     width: 100%;
 }

 /* editor css */

 .gjs-pn-views-container {
     right: 0 !important;
 }

 .gjs-cv-canvas {
     max-width: 70%;
     margin: 0 auto;
     justify-content: center;
     display: flex;
     right: 0;
 }

 .gjs-editor-cont {
     height: 92vh !important;
 }

 .gjs-pn-options {
     margin: 0 auto !important;
     justify-content: center !important;
     width: 70% !important;
 }

 .gjs-pn-buttons {
     justify-content: center !important;
 }

 .gjs-pn-devices-c {
     z-index: 999999 !important;
 }

 .gjs-openBlocks {
     display: none !important;
 }

 :not(:root):fullscreen::backdrop {
     position: static !important;
     ;
 }

 .left_panel {
     position: fixed;
     top: 6.75rem;
     z-index: 1;
     width: 15%;
     height: 100vh;
     overflow-x: hidden;
     overflow-y: auto;
     padding-bottom: 1.875rem;
 }

 .left_panel::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
     background-color: #54575e;
 }

 .left_panel::-webkit-scrollbar {
     width: 0.375rem;
     background-color: #54575e;
 }

 .left_panel::-webkit-scrollbar-thumb {
     background-color: #2a2f38;
 }

 .left_panel .gjs-blocks-cs {
     padding-bottom: 7.5rem;
 }

 .gjs-cv-canvas {
     height: 89.1vh !important;
 }

 .gjs-pn-views-container {
     height: 92.9vh !important;
 }

 .gradient-box {
     background: linear-gradient(90deg, #af00ff, #001eff);
     border-radius: .9375rem;
     padding: 1px;
 }

 .site-switch {
     width: 2.2rem;
     height: 1.2rem;
 }

 .slider:before {
     bottom: 0.23rem;
 }

 input:checked+.site-switch .slider {
     background-color: #18BD15;
     height: 1.2rem;
     width: 2.2rem;
 }

 .Universal_modal_search-container {
     position: relative;
     width: 100%;
 }

 .Universal_modal_search-btn {
     background: var(--link);
     border: none;
     color: var(--white);
     cursor: pointer;
     font-size: 1.125rem;
     height: 100%;
     padding: 0;
     position: absolute;
     right: 0;
     top: 50%;
     transform: translateY(-50%);
     width: 5.625rem;
 }

 .primary-gradient {
     width: 100%;
 }

 .emoji-custom:hover {
     color: var(--link);
 }

 .Universal_images_searchContent {
     height: 27.125rem;
     margin-top: 1.25rem;
     overflow-x: hidden;
     overflow-y: auto;
     padding-right: .625rem;
     scrollbar-color: #b4b6b9 #373d49;
     scrollbar-color: var(--border-color) var(--border-color);
     scrollbar-width: thin;
 }

 @media (max-width: 1699.95px) {
     .vidHover-single {
         justify-content: center;
     }
 }

 @media screen and (max-width:1199px) {
     .site-hero-wrap {
         padding-left: 0rem;
     }

     .min-kap {
         margin-top: 5px;
         min-height: 40px;
     }
 }

 @media screen and (max-width:992px) {
     .tempList ul li {
         width: 33%;
     }
 }

 @media screen and (max-width:767px) {
     .tempList ul li {
         width: 50%;
     }

     .site-hero-wrap .projectTitle-wrap {
         top: 7rem;
         padding: 40px 35px 20px;
         gap: 15px !important;
     }

     .site-hero-wrap .project-input-in .inp {
         padding: 8px 15px;
     }

     .site-hero-wrap .projectTitle {
         gap: 15px;
     }

 }

 @media screen and (max-width:576px) {

     .tempList ul li {
         width: 100%;
     }

     .modal-content {
         margin: 10px;
     }
 }