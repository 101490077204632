.white_label_marketing_wrap {
    padding-left: 18.625rem;
    padding-right: 0;
    width: 100%;
}

/* .white_label_image_marketing .cont-box {
    padding: 30px;
} */

.white_label_image_marketing .videoProject-single-bottom {
    color: var(--black);
}

.white_label_image_marketing .projectTitle-wrap {
    border-radius: 0;
}

.white_label_image_marketing .projectTitle {
    width: 100%;
}

.white_label_image_marketing .projectTitle-left h2 {
    color: var(--black);
}

.videoProject-single-top-1,
.videoProject-single-top-2 {
    border-radius: .9375rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.videoProject-single-top-2 {
    height: 400px;
}


.white_label_image_marketing .tabSearch {
    position: relative;
    border: 1px solid var(--link);
    border-radius: 100%;
    border-radius: 100px;
}

.white_label_image_marketing .tabSearch input {
    background: transparent;
    border: 0;
    border-radius: 0;
    padding: 13px 40px 13px 45px !important;
}

.inner-tab .nav-link.active {
    color: var(--link) !important;
    font-weight: 600;
}

.white_label_image_marketing .tabSearch .inp-icon {
    left: 15px;
}

.white_label_image_marketing .projectTitle-wrap {
    align-items: center;
    background: var(--white);
    border-bottom: .0625rem solid #ffffff1a;
    display: flex;
    min-height: 5rem;
    justify-content: space-between;
    padding: 0 2.5rem;
    position: sticky;
    top: 5.625rem;
    width: 100%;
    /* z-index: 99; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
}

/* .white_label_image_marketing .content_image_top {
    background: var(--white);
    border-bottom: .0625rem solid #ffffff1a;
    min-height: 5rem;
    padding: 0 2.5rem;
    position: sticky;
    top: 5.625rem;
    width: 100%;
    z-index: 99;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1019607843) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */


/* .white_label_image_marketing */
.white_label_image_marketing .videoProject-single {
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    transition: all .2s linear;
}

.white_label_image_marketing .videoProject-single-1 {
    aspect-ratio: 16 / 9;
    border-radius: .9375rem;
    width: 100%;
    height: 180px;
}


.white_label_image_marketing .videoProject-single-top-1,
.videoProject-single-top-2 {
    border-radius: .9375rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.white_label_image_marketing .videoProject-single-top-1 {
    height: 100%;
}

.white_label_image_marketing .vidHover-single a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-inline-end: 4px;
}

.white_label_image_marketing .vidHover {
    align-items: center;
    background-color: #000c;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0 1.875rem;
    position: absolute;
    top: 0;
    transition: all .5s ease;
    visibility: hidden;
    width: 100%;
}

.white_label_image_marketing .videoProject-single:hover .vidHover {
    opacity: 1;
    visibility: visible;
    top: 0;
}

.white_label_image_marketing .vidHover-icon {
    background: #fff;
    border-radius: 100%;
    color: #000;
    font-size: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;


}


.white_label_image_marketing .qz-tag {
    border-radius: .75rem;
    color: #fff;
    display: inline-block;
    font-size: .75rem;
    font-weight: 600;
    left: .75rem;
    padding: .25rem .8125rem;
    position: absolute;
    top: .75rem;
    z-index: 1;
}

.white_label_image_marketing .video-card-wrapper-1 {
    /* background: linear-gradient(190deg, #e512c9, #5d00ff); */
    background: var(--black);
    cursor: pointer;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.white_label_image_marketing .video-card-content,
.white_label_image_marketing .video-card-content-2 {
    height: 100%;
    width: 100%;
}

.white_label_image_marketing .vidHover-icon i {
    font-size: 15px;
    font-weight: 900;
}

.white_label_image_marketing .video-card-content {
    align-items: center;
    background: var(--border-color);
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-height: 100%;
    transition: all .3s ease;
}

/* editor */

.white_label_image_marketing .content-editor-wrap {
    align-items: flex-start;
    display: flex;
    margin-top: 15px;
}

.white_label_image_marketing .content-editor-left-in {
    background: #32cd32 !important;
    background: var(--primary) !important;
    border: 0.0625rem solid var(--border-color) !important;
    border-radius: 0.75rem;
    overflow: hidden;
    width: 480px;

}

.white_label_image_marketing .editor-tab-left {
    margin: 0;
    padding: 30px 0;
    width: 150px;
}

.white_label_image_marketing .flex-column {
    flex-direction: column !important;
}

.white_label_image_marketing .editor-tab-left .nav-item {
    margin: 4px 0;
}

.white_label_image_marketing .editor-tab-left .nav-link.active {
    background: #141414;
    background: var(--link);
    color: #fff;
}

.white_label_image_marketing .editor-tab-left .nav-link {
    align-items: center;
    color: var(--color-5);
    display: flex;
    font-size: 15px;
    margin: 0;
    padding: 10px 12px;
}


.white_label_image_marketing .editor-tab-left .nav-link span {
    display: inline-block;
    padding-right: 8px;
    transform: translateY(-1px);
}

.white_label_image_marketing .editor-tab-left .nav-link span svg {
    height: 20px;
    width: 20px;
}

.white_label_image_marketing .content-editor-tab .tab-content {
    background: #fff;
    background: var(--white);
    padding: 32px 5px 18px 20px;
    position: relative;
    width: calc(100% - 150px);
    z-index: 1;
}

.white_label_image_marketing .inner-tab {
    height: 590px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 5px;
    padding-top: 14px;
}

.white_label_image_marketing .inner-tab .nav {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.white_label_image_marketing .inner-tab .nav-item:first-child {
    margin-left: 0;
}



.white_label_image_marketing .inner-tab .nav-link {
    color: #000;
    color: var(--black);
    font-size: 14px;
    font-weight: 500;
    padding: 0;
}

.white_label_image_marketing .inner-tab .tab-content {
    padding: 13px 0 0;
    width: 100%;
}

.white_label_image_marketing .content-editor-right {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 15px;
    padding-left: 30px;
    width: calc(100% - 405px);
}

.white_label_image_marketing .image-editor-img_square {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    max-height: 100%;
    min-height: 474px;
    position: relative;
    width: 100%;
}

.white_label_image_marketing .image-editor .editor-bar {
    width: calc(100% - 60px);
}

.white_label_image_marketing .content-editor-tab {
    display: flex;
    justify-content: space-between;
}



.white_label_image_marketing .editor-bar {
    align-items: center;
    background: #32cd32 !important;
    border-radius: 12px;
    border-radius: 30px;
    display: flex;
    height: 36px;
    height: 40px !important;
    justify-content: space-between;
    justify-content: center;
    margin: 18px auto 0;
    max-width: 854px;
    padding: 0 15px;
    padding: 0 20px;
    width: 100%;
}

.white_label_image_marketing .editor-bar-left ul {
    align-items: center;
    display: flex;
    height: 100%;
}

.white_label_image_marketing .editor-bar-left ul li {
    cursor: pointer;
    margin: 0 5px;
}

.white_label_image_marketing .whiteFont {
    color: #fff;
    font-size: 20px;
}


.white_label_image_marketing .slider:before {
    display: none;
}



.white_label_image_marketing .font-heading,

.white_label_image_marketing .inp-wrap,

.white_label_image_marketing .tab-content .box-cont {
    margin-bottom: 20px;
}


.white_label_image_marketing .font-heading ul li:first-child {
    margin-top: 0;
}


.white_label_image_marketing .box-cont-in,

.white_label_image_marketing .font-heading ul li {
    background: #fff;
    background: var(--white);
    border: 1px solid #cfcfcf !important;
    color: #000;
    color: var(--black);
}



.white_label_image_marketing .font-heading ul li {
    background: #1a1944;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    padding: 11px 5px;
    text-align: center;
}

/* .font-heading,
.inp-wrap,
.tab-content .box-cont {
    margin-bottom: 20px;
} */


.white_label_image_marketing .box-cont label {
    color: #000;
    color: var(--black);
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
}


.white_label_image_marketing .box-cont-in {
    border-radius: 5px;
    padding: 15px;
    position: relative;
}


.white_label_image_marketing .box-cont-in,

.white_label_image_marketing .font-heading ul li {
    background: #fff;
    background: var(--white);
    border: 1px solid #cfcfcf !important;
    color: #000;
    color: var(--black);
}


.white_label_image_marketing .txt-size {
    align-items: center;
    display: flex;
    justify-content: space-between;
}


.white_label_image_marketing .slidecontainer {
    width: calc(100% - 80px);
}


.white_label_image_marketing .slidecontainer .custom-range {
    width: 65% !important;
}


.white_label_image_marketing .slidecontainer input,

.white_label_image_marketing .sliderN {
    margin: auto;
    width: 100%;
}


.white_label_image_marketing .slider {
    -webkit-appearance: none;
    background: #fff;
    background: #cfcfcf;
    border-radius: 10px;
    opacity: .7;
    outline: none;
    transition: opacity .2s;
    width: 100%;
}


.white_label_image_marketing .slider,

.white_label_image_marketing .slider:before {
    position: absolute;
    transition: .4s;
}


.white_label_image_marketing .slider {
    background-color: #636363;
    bottom: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    top: 0;
}


.white_label_image_marketing .txt-size span:last-child {
    font-size: 20px;
}


.white_label_image_marketing .txt-size span {
    color: #000;
    color: var(--black);
}


.white_label_image_marketing .font-heading,

.white_label_image_marketing .inp-wrap,

.white_label_image_marketing .tab-content .box-cont {
    margin-bottom: 20px;
}


.white_label_image_marketing .type-settings ul li {
    color: #000;
    color: var(--black);
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    line-height: 18px;
    /* margin-left: 10px; */
}

.white_label_image_marketing .selected-color {
    background: #18185e;
    border-radius: 5px;
    height: 30px;
    width: 30px;
}

.white_label_image_marketing .color-select {
    align-items: center;
    display: flex;
}

.white_label_image_marketing .type-settings ul {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.white_label_image_marketing .inner-tab .nav-item {
    margin-left: 15px;
}

.innertab-scroll {
    height: calc(100vh - 20.7rem);
    /* margin-right: -.9375rem; */
    margin-top: 1.25rem;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: .9375rem;
}

.infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden !important;
}

.media-ul-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;
    padding: .5rem;
}

.myImage-section {
    position: relative;
}

.media-ul-list li {
    flex: 0 0 50%;
    margin-top: .125rem;
    max-width: 50%;
    min-height: .0625rem;
    padding: .0625rem;
    width: 100%;
}

.media-ul-list li:first-child,
.media-ul-list li:nth-child(2) {
    margin-top: 0;
}

.crop-icon,
.slide-active {
    position: absolute;
    z-index: 2;
}

.crop-icon {
    color: #ffa200;
    cursor: pointer;
    height: auto !important;
    left: 1.0625rem;
    top: .3125rem;
    width: auto !important;
}

.infinite-scroll-component i {
    color: var(--link);
}

.myImage-respo img {
    width: 100%;
}

.content-editor-wrap {
    display: flex;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    margin-top: 15px;
}

.content-editor-wrap.alt .editor-left {
    width: auto;
}

.creator-img {
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.content-editor-tab {
    display: flex;
    justify-content: space-between;
}

.editor-tab-left {
    width: 150px;
    margin: 0;
    padding: 30px 0;
}

.editor-tab-left .nav-link.active {
    background: var(--secondary);
    color: #fff;
}

.editor-tab-left .nav-link span {
    display: inline-block;
    padding-right: 8px;
    transform: translateY(-1px);
}

.editor-tab-left .nav-link span svg {
    width: 20px;
    height: 20px;
}


.editable {
    height: auto;
    border-radius: 43px;
    margin-top: 30px;
    /* background: var(--dark-blue); */
}

.editor-controller {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 290px;
    border-radius: 43px;
    background: var(--dark-blue);
    padding: 20px;
}

.content-editor-right {
    /* height: calc(100vh - 325px); */
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 15px;
}

.editor-right::-webkit-scrollbar {
    width: 5px;
}

.inner-tab .tab-content {
    padding: 13px 0 0;
    width: 100%;
}

.inner-tab {
    height: 590px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 5px;
    padding-top: 14px;
}

.inner-tab::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
}

.editor-right::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
}

.editor-right::-webkit-scrollbar-thumb {
    background: #00d0b0;
    border-radius: 50px;
}

.control-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    background: rgba(29, 27, 65, 0.3);
    height: 36px;
    padding: 0 30px;
}

.control-bar-left ul {
    display: flex;
    align-items: center;
}

.control-bar-left ul li {
    font-size: 18px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.control-bar-left ul li:first-child {
    margin-left: 0;
}

.control-bar-left ul li span {
    font-size: 12px;
    font-weight: 300;
    padding-left: 5px;
}

.slidecontainer.alt {
    width: 100px;
    transform: translateY(-5px);
}

.timeline {
    padding: 30px 0 0 0;
}

.timeline ul {
    display: flex;
    align-items: center;
}

.timeline ul li {
    margin-left: 10px;
}

.timeline ul li:first-child {
    margin-left: 0;
}

.timeline-single {
    width: 148px;
    height: 82px;
    border-radius: 16px;
}

.timeline-single img {
    width: 148px;
    height: 82px;
    object-fit: cover;
    border-radius: 16px;
}

.add-timeline {
    width: 146px;
    height: 80px;
    border-radius: 16px;
    border: 1px solid #e0e0e0;
    background: rgba(29, 27, 65, 0.3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.add-timeline svg {
    font-size: 24px;
}

.timeline-wrap {
    padding: 0 30px;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}

.font-heading ul li {
    background: #1a1944;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 8px;
    padding: 11px 5px;
    text-align: center;
    font-weight: 500;
}

.box-cont-in,
.font-heading ul li {
    background: var(--white);
    border: 1px solid #cfcfcf !important;
    color: var(--black);
}

.font-heading ul li:first-child {
    margin-top: 0;
}

.box-cont label {
    display: block;
    font-size: 14px;
    padding-bottom: 5px;
    color: var(--black);
    font-weight: 500;
}

.box-cont-in {
    /* background: #1a1944; */
    border-radius: 5px;
    padding: 15px;
    position: relative;
}

.txt-size {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.txt-size span {
    color: var(--black);
}

.slider {
    -webkit-appearance: none;
    background: #fff;
    border-radius: 10px;
    opacity: .7;
    outline: none;
    transition: opacity .2s;
    width: 100%;
}

.slider {
    background: #cfcfcf;
}

.slidecontainer {
    width: calc(100% - 80px);
}

.slidecontainer input,
.sliderN {
    width: 100%;
    margin: auto;
}

.custom-range::before {
    content: unset !important;
}

.image_preview_wrapper_9x16 {
    max-width: 360px;
    width: 100%;
    height: 640px;
    margin: 0 auto;
    background: #141414;
    border: 0.0625rem solid rgba(255, 255, 255, 0.10);
    overflow: hidden;
    border-radius: 12px;
    margin-block: 30px;
}

.image_preview_wrapper_9x16 img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.txt-size span:last-child {
    font-size: 20px;
}

.siteWrap_9x16 {
    height: 100vh !important;
}

.inp-wrap label {
    color: var(--black);
    display: block;
    font-size: 14px;
    padding-bottom: 5px;
}

.color-select {
    display: flex;
    align-items: center;
}

.selected-color {
    background: #18185e;
    border-radius: 5px;
    height: 23px;
    width: 23px;
}

.type-settings ul li {
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    line-height: 18px;
    color: var(--black);
}


.tab-content .box-cont,
.font-heading,
.inp-wrap {
    margin-bottom: 20px;
}

.inner-tab .nav {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.inner-tab .nav-item:first-child {
    margin-left: 0;
}

.inner-tab .nav-item {
    margin-left: 15px;
}



.list-images ul {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.content-editor-tab .sketch-picker {
    top: 83%;
    left: 0;
}

.image-uploader {
    align-items: center;
    background: #1a1944 !important;
    border: 1px dashed #48469f !important;
    border-radius: 15px;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 120px;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.select-tile-style {
    border: 2px solid var(--link);
    font-size: 28px;
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: 8px;
    width: 100%;
}

.list-delete {
    align-items: center;
    border-radius: 5px;
    color: #513636;
    cursor: pointer;
    display: flex;
    font-size: 19px;
    height: 20px;
    justify-content: center;
    opacity: 0;
    position: absolute;
    right: 9px;
    top: 9px;
    width: 20px;
}

.text-picker h6 {
    color: var(--black);
    font-weight: 500;
    margin-bottom: 10px;
}

.add-layer .layer {
    background-color: var(--black);
    cursor: pointer;
    height: 40px;
    width: 100%;
}

.border-style {
    color: var(--black);
    cursor: pointer;
    display: block;
    margin-bottom: 45px;
    min-height: 50px;
}

.upload_scrolls {
    max-height: 385px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
}

.solid-border {
    border: 3px solid var(--black);
}

.dashed-border {
    border: 3px dashed var(--black);
}

.dashed-border-light {
    border: 2px dashed var(--black);
}

.dotted-border {
    border: 3px dotted var(--black);
}

.add-layer {
    margin-bottom: 20px;
}

.image-uploader {
    background: var(--white) !important;
    border: 1px dashed #cfcfcf !important;
    color: var(--black);
}

.image-uploader svg {
    font-size: 22px;
}

.image-uploader p {
    font-size: 14px;
    color: var(--black);
    padding-top: 10px;
}

.image-uploader input {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}

/* .tab-content .sketch-picker {
    width: 100% !important;
} */

.font-block h5 {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

textarea.inp-small {
    height: 165px;
    padding-top: 20px;
}

.inp-small {
    background: var(--white);
    border: 1px solid #cfcfcf;
    color: var(--black);
    font-size: 13px;
    padding: 10px 5px;
    border-radius: 6px;
    width: 100%;
}

.cont-search {
    position: relative;
    margin-bottom: 10px;
}

.cont-search.with-icon .cont-inp {
    padding-left: 42px;
}

.cont-inp {
    border: .5px solid #dfdfdf;
    border-radius: 50px;
    font-size: 14px;
    padding: 9px 20px;
    width: 100%;
}

.cont-search span {
    color: var(--primary);
    left: 20px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.icon-items .emoji-custom i {
    cursor: pointer;
    font-size: 30px;
}

.emoji-custom {
    margin-bottom: 10px;
    margin-right: 10px;
}

.icon-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}



.image-editor .editor-bar {
    width: calc(100% - 60px);
}


.editor-bar {
    align-items: center;
    background: var(--link);
    border-radius: 12px;
    display: flex;
    height: 36px;
    justify-content: space-between;
    padding: 0 15px;
}



.editor-bar-left ul {
    align-items: center;
    display: flex;
    height: 100%;
}

.editor-bar-left ul li {
    margin: 0 5px;
    cursor: pointer;
}

.whiteFont {
    color: #fff;
    font-size: 20px;
}

.list-images-single {
    border-radius: 10px;
    height: 70px;
    overflow: hidden;
    position: relative;
}

.list-images-single img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.list-images-single:hover .list-delete {
    opacity: 1;
}

.list-images-single {
    border: 1px solid #cfcfcf !important;
    cursor: pointer;
}

.loader-center-temp1,
.select-tile-style {
    align-items: center;
    color: var(--link);
    display: flex;
    height: 100%;
    justify-content: center;
}

.video-card-content img {
    height: 60px;
    width: 150px;
    margin-block: 0 !important;
    object-fit: contain;
}

.icon-items .emoji-custom span {
    cursor: pointer;
    font-size: 35px;
}

.select-tile-style {
    z-index: 1;
}

.shape-box .shape-item .triangle {
    /* background-color: #fff; */
    width: 61px;
    height: 53px;
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item2 a,
.shape-box .shape-item a {
    width: 46px;
    height: 46px;
    /* background-color: #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a {
    border-radius: 5px;
    cursor: pointer;
}

.shape-box .shape-item2 a {
    border-radius: 5px;
    cursor: pointer;
}

.shape-box .shape-item3 a {
    width: 66px;
    height: 46px;
    /* background-color: #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item3-border a {
    width: 66px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item .cliped-circle {
    width: 55px;
    height: 55px;
    background-color: var(--black);
    border-radius: 50%;
    position: relative;
}

.shape-box .shape-item .cliped-triangle,
.shape-box .shape-item .cliped-triangle:before {
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item .cliped-triangle {
    background-color: var(--black);
    width: 61px;
    height: 53px;
    position: relative;
}

.shape-box .shape-item .cliped-circle:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 0;
    background-color: #fff;
    width: 49px;
    height: 49px;
    border-radius: 50%;
}

.shape-box .shape-item .cliped-triangle:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 6px;
    right: 0;
    bottom: 0;
    width: 49px;
    height: 45px;
    background-color: #fff;
}

.custom-search {
    color: var(--black);
    padding: 13px 40px !important;
    border-radius: 50px !important;
}

.inpx-icon {
    font-size: 22px;
    left: 30px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-53%);
    transform: translateY(-53%);
}

.list-images ul li {
    margin-top: 10px;
    padding: 0 5px;
    width: 50%;
}


.shape-box .shape-item .triangle {
    /* background-color: #fff; */
    width: 61px;
    height: 53px;
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item2 a,
.shape-box .shape-item a {
    width: 46px;
    height: 46px;
    /* background-color: #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a {
    border-radius: 5px;
    cursor: pointer;
}

.shape-box .shape-item2 a {
    border-radius: 5px;
    cursor: pointer;
}

.shape-box .shape-item3 a {
    width: 66px;
    height: 46px;
    /* background-color: #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item3-border a {
    width: 66px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item .cliped-circle {
    width: 55px;
    height: 55px;
    background-color: var(--black);
    border-radius: 50%;
    position: relative;
}

.shape-box .shape-item .cliped-triangle,
.shape-box .shape-item .cliped-triangle:before {
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item .cliped-triangle {
    background-color: var(--black);
    width: 61px;
    height: 53px;
    position: relative;
}

.slider:before {}

.shape-box .shape-item a,
.shape-box .shape-item2 a,
.shape-box .shape-item3 a {
    background-color: var(--black);
}

.shape-box .shape-item-border a,
.shape-box .shape-item2-border a,
.shape-box .shape-item3-border a {
    border: 2px solid var(--black);
}

.inpNum {
    -webkit-appearance: auto !important;
}

.inpNum::-webkit-inner-spin-button,
.inpNum::-webkit-outer-spin-button {
    -webkit-appearance: auto !important;
}

.shape-box .shape-item .cliped-circle:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 0;
    background-color: #fff;
    width: 49px;
    height: 49px;
    border-radius: 50%;
}

.shape-box .shape-item .cliped-triangle:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 6px;
    right: 0;
    bottom: 0;
    width: 49px;
    height: 45px;
    background-color: #fff;
}

.site-wrap {
    padding-top: 130px;
}

.site-wrap {
    min-height: calc(100vh - 81px);
}

.site-wrap {
    padding-bottom: 80px;
    padding-top: 100px;
}

.site-container {
    padding-left: 50px;
    padding-right: 50px;
}

.kap .appHeader-wrap {
    padding-left: 0;
}

.kap .logo-full {
    display: block !important;
    max-width: 11rem;
}

.kap .appFooter.innerPage {
    padding-left: 0px;
}


.siteWrap.kap {
    height: 82.3vh;
    min-height: 100%;
}

.image_preview_wrapper {
    max-width: 854px;
    width: 100%;
    height: 480px;
    overflow: hidden;
    border-radius: 12px;
    margin: 0 auto;
    margin-block-start: 50px;
    background: #141414;
    border: 0.0625rem solid rgba(255, 255, 255, 0.10);
}

.image_preview_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.projectTitle-wrap.kap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.kap .site-footer {
    padding-left: 0;
}


@media screen and (max-width:1399px) {
    .inner-tab .nav {
        justify-content: center;
        gap: 5px;
    }
}

@media (max-width:1199.98px) {
    .white_label_marketing_wrap {
        padding-left: 0;
    }

    .white_label_image_marketing .content-editor-wrap {
        flex-wrap: wrap;
    }

    .white_label_image_marketing .content-editor-left {
        margin: 0 auto;
    }

    .white_label_image_marketing .content-editor-right {
        margin-top: 40px;
        padding: 0;
    }

    .white_label_image_marketing .content-editor-left,
    .white_label_image_marketing .content-editor-right {
        align-self: stretch;
        width: 100%;
    }

    .white_label_image_marketing .content-editor-left-in {
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .mob-title-wrap {
        margin: 60px 0px 20px;
    }

    @media screen and (max-width:576px) {
        .mob-flex {
            flex-direction: column;
        }

        .mob-title-wrap {
            align-items: start !important;
            flex-direction: column !important;
            gap: 20px;
            margin: 60px 0px 20px;
        }



        .white_label_image_marketing .slidecontainer .custom-range {
            width: 45% !important;
        }
    }

}