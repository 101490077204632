// CSS for Header and Footer

.site-header{ display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; height: 3.75rem; padding: 0 2.375rem; border-bottom: 0.0625rem solid var(--border-color); position: fixed; width: 100%; left: 0; top: 0; z-index: 9; background: var(--bg-color); padding-left: 20.75rem;}

.drop-style-1 button{ border: none !important; padding: 0 !important; color: var(--font-color);}
.drop-style-1 button:focus{ outline: 0 !important; box-shadow: none;}
.user-btn{ display: flex; align-items: center; gap: 0 0.625rem;}
.user-btn h6{ font-size: 0.8125rem; font-weight: 500; color: var(--font-color);}
.user-btn p{ font-size: 0.75rem; color: #8b8e9c;}

.drop-img{ width: 2rem; height: 2rem; border-radius: 100%; overflow: hidden;}
.drop-img img{ width: 100%; height: 100%; object-fit: cover;}
.drop-info{ text-align: left;}

.side-link ul li ul{ display: none;}

.drop-style-1 .dropdown-toggle::after{ display: none;}
.drop-style-1 .dropdown-menu{ font-size: 0.8125rem; padding: 0.625rem; width: max-content; background: var(--white); border: 0.0625rem solid var(--border-color);}
.drop-style-1 .dropdown-menu a{ display: flex; align-items: center; gap: 0 0.5rem; font-size: 0.8125rem; font-weight: 400; padding: 0.5rem 0.5rem; border-radius: 0.3125rem; color: var(--font-color);}
.drop-style-1 .dropdown-menu a svg{ font-size: 1.125rem;}
.drop-style-1 .dropdown-menu a:hover{ background: var(--link); color: var(--white);}

.site-header .dropdown-menu{ margin-top: 0.9375rem;}

.drop-style-1.bdr button{ border: 2px solid var(--link) !important; border-radius: 100px !important; padding: 4px 5px !important;}
.drop-style-1.bdr button{ padding-right: 15px !important;}
.drop-style-1.bdr .dropdown-toggle::after{ display: block;}
.drop-style-1.bdr .dropdown-menu{ margin-top: 5px !important;}

.header-right{ display: flex; align-items: center; gap: 0 1.5625rem;}
.upgrade-btn{ display: inline-flex; align-items: center; justify-content: center; background: none; color: var(--link); border: none; font-size: 0.8125rem; font-weight: 600; gap: 0 0.3125rem;}
.upgrade-btn svg{ font-size: 1.25rem;}


.compDrop{ border: 0.125rem solid var(--link) ; border-radius: 0.9375rem; display: flex !important; flex-direction: column; gap: 0.3125rem 0;
    a{ background: #ededed; border-radius: 0.625rem !important; height: 3rem; padding: 0 0.9375rem !important;} 
} 


// 
.header-logo img{ max-height: 2.5rem;}
.header-search{ position: relative;}
.header-search input{ border-radius: 1.3125rem; border: 0.0625rem solid #CCC; background: #FFF; padding: 0.4375rem 1.5625rem; padding-left: 2.5rem; width: 24.5625rem;}
.header-search span{ position: absolute; left: 0.9375rem; top: 50%; transform: translateY(-50%);}

.header-logo .logo-small{display: none;}
// 


.header-logo{ height: 3.75rem; display: flex; align-items: center;}  


// Footer
.site-footer{ padding-left: 18.75rem;}
.site-footer-in{ padding: 0 1.875rem; display: flex; align-items: center; justify-content: space-between; font-size: 0.8125rem; height: 3.75rem; border-top: 0.0625rem solid var(--border-color);}
.footer-link ul{ display: flex; align-items: center;}
.footer-link ul li{ position: relative; margin: 0 0 0 1.5625rem;}
.footer-link ul li:first-child{ margin-left: 0;}
.footer-link ul li::after{ content: ''; position: absolute; right: -0.8125rem; top: 0.25rem; width: 0.0625rem; height: 0.75rem; background: var(--font-color);}
.footer-link ul li:last-child::after{ opacity: 0;}
.footer-link ul li a{ color: var(--font-color); font-size: 0.75rem;}
.footer-link ul li a:hover{ color: var(--link);}
.footer-logo img{ width: 9.375rem;}

.footer-expand .site-footer{ padding-left: 5.5rem;}

.login-footer{
    .site-footer{ padding-left: 0;} 
}

.header-left{ display: flex; align-items: center; gap: 0 5px; 
    .header-logo{ display: none;} 
    .site-link{ display: none;}
} 
.navToggle{ display: none;}
.navToggle svg{ font-size: 22px; }

@media (max-width: 1199px) {
    .site-footer{ padding: 0;}
    .site-header{ padding: 0 20px;}

    .header-left{ 
        .header-logo{ display: flex;} 
    } 

    .navToggle{ display: block;}
}

@media (max-width: 1023px) {
    .site-footer-in{ flex-wrap: wrap; height: auto; padding: 20px; border-top: 1px solid var(--border-color);}
    .footer-link{ width: 100%;}
    .footer-link ul{ justify-content: center;}
    .footer-copy{ width: 100%; text-align: center; padding: 15px 0;}
    .footer-logo{ width: 100%; text-align: center;}
}

@media (max-width: 767px) {
    // .site-header{ height: 100px; padding-top: 8px; padding-bottom: 8px;}
    .site-header{ height: auto; padding:8px 10px; background: #FFF;}
    .header-right{ width: 100%; border-top: 1px solid #ededed; padding-top: 8px; justify-content: space-between;
        .site-link{ display: none;}
    }
    .header-left{ justify-content: space-between; width: 100%;
        .site-link{ font-size: 12px; padding-left: 8px; padding-right: 8px; display: inline-flex;}
    }
}