h2 {
    font-size: 1.25rem;
}

.page-wrap {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 3.75rem);
    padding-top: 3.75rem;
}

.side-nav {
    width: 18.75rem;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 0.0625rem solid var(--border-color);
    padding: 0 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--bg-color);
    z-index: 999;
}

.side-link ul li {
    margin-bottom: 0.5rem;
}

.side-link ul a,
.navButton {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: rgba(30, 30, 30, 0.7);
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    width: 100%;
    background: none;
}

body .kap_nav{ color: rgba(30, 30, 30, 0.7);}

.side-link ul a span,
.navButton span {
    display: inline-flex;
    align-items: center;
    gap: 0 0.625rem;
}

.side-link ul a svg,
.navButton svg {
    font-size: 1.375rem;
}

.side-link ul a:hover,
.navButton:hover {
    background: var(--link);
}

.side-link ul a:hover span,
.navButton:hover span {
    color: var(--white);
}

.side-link ul a:hover span svg,
.navButton:hover span svg {
    color: var(--white);
}

.side-link ul a.active,
.navButton.active {
    background: var(--link);
}

.side-link ul a.active span,
.navButton.active span {
    color: var(--white);
}

.side-link ul a.active span svg,
.navButton.active svg {
    color: var(--white);
}

.side-link ul li a+ul {
    display: none;
}

.dropItem .collapse {
    background: #FFF;
    padding: 0.625rem;
    border-radius: 0.625rem;
}

.dropItem:has(.collapse.show) .navButton {
    background: var(--link);
}

.dropItem:has(.collapse.show) .navButton span {
    color: var(--white);
}

.dropItem:has(.collapse.show) .navButton svg {
    color: var(--white);
}

.dropItem:has(a.active) .collapse {
    display: block !important;
}

.dropItem:has(a.active) .navButton {
    background: var(--link);
    pointer-events: none;
}

.dropItem:has(a.active) .navButton span {
    color: var(--white);
}

.dropItem:has(a.active) .navButton svg {
    color: var(--white);
}


.dropItem a {
    padding: 0.625rem 0.75rem !important;
}

.socialLink {
    margin-top: 1.25rem;
}

.socialLink ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 1.25rem;
}

.socialLink ul li a {
    color: var(--font-color);
}

.socialLink ul li a svg {
    font-size: 1.25rem;
}

.socialLink ul li a:hover {
    color: var(--button-1);
}

.page-container {
    width: 100%;
    padding: 2.125rem 1.875rem;
    padding-left: 20.625rem;
}

.page-container.alt{ padding: 0; padding-left: 18.75rem;}

.page-container.alt .container{ max-width: 90rem !important;}

.page-container-in {
    max-width: 71.5rem;
    margin: 0 auto;
}

.page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page-title h2 {
    font-size: 1.125rem;
    font-weight: 700;
}

.start-box-single {
    border-radius: 1rem;
    background: var(--white);
    border: 0.0625rem solid var(--border-color);
    padding: 1.5625rem;
}

.start-box-img {
    width: 9.375rem;
    height: 9.375rem;
    background: #767676;
    border-radius: 0.75rem;
    overflow: hidden;
}

.start-box-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.start-box-single h2 {
    font-size: 1.5rem;
}

// .start-box-single p {
//     color: #BFBFBF;
// }

.project-card-img {
    height: 15.0625rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;
}

.project-card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-card-info {
    background: var(--white);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0.75rem 1.125rem;
    font-size: 0.625rem;
}

.project-card-info h6 {
    font-size: 0.875rem;
    padding-bottom: 0.375rem;
}


// .modal .input{ background: #232424;}
.h-160 {
    height: 10rem;
}

.h-500 {
    height: 31.25rem;
}

.modal-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0 0.9375rem;
}

.slick-list {
    margin: 0 -0.5rem;
}

.slick-slide>div {
    padding: 0 0.5rem;
}

.slick-track {
    margin: 0;
}

.slOption {
    background: #232424;
    border: 0.0625rem solid #404040;
    border-radius: 0.5rem;
    padding: 1.25rem 1.125rem;
}

.slOption h6 {
    font-size: 0.875rem;
}

.slOption p {
    font-size: 0.75rem;
    color: #BFBFBF;
    padding: 0.375rem 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.pro-script {
    padding-top: 3.75rem;
}

.pro-script-tab {
    background: var(--primary-color);
    border-bottom: 0.0625rem solid var(--border-color);
    position: sticky;
    top: 3.75rem;
    z-index: 2;
}

.pro-script-tab-in {
    height: 3.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-style-2 {
    gap: 0 4.375rem;
}

.nav-style-2 .nav-link {
    border-radius: 0.375rem;
    background: #151616;
    padding: 0.625rem 1.25rem;
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--font-color);
}

.nav-style-2 .nav-link.active {
    background: linear-gradient(90deg, var(--button-1) 0%, var(--button-2) 100%);
}

.nav-style-2 .nav-item {
    position: relative;
}

.nav-style-2 .nav-item::after {
    content: '';
    position: absolute;
    right: -2.5rem;
    top: 0.625rem;
    width: 0.75rem;
    height: 0.75rem;
    background: url('../images/icon/next.svg') no-repeat;
}

.nav-style-2 .nav-item:last-child::after {
    opacity: 0;
}

.input-idea {
    width: 50.0625rem;
    margin: 0 auto;
    padding: 2.625rem 0;
    padding-bottom: 7.5rem;
}

.next-bar {
    width: 100%;
    height: 3.875rem;
    background: var(--primary-color);
    border-top: 0.0625rem solid var(--border-color);
    padding: 0 1.5625rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 0.9375rem;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
}

.nav-style-3 {
    border: 0.0625rem solid #404040;
    border-radius: 0.375rem;
    padding: 0.25rem;
}

.nav-style-3 .nav-item {
    flex: 1;
}

.nav-style-3 .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1.25rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--font-color);
    border-radius: 0.25rem;
    gap: 0 0.5rem;
}

.nav-style-3 .nav-link svg {
    font-size: 1.25rem;
}

.nav-style-3 .nav-link.active {
    background: linear-gradient(90deg, var(--button-1) 0%, var(--button-2) 100%);
}

.txt-length {
    padding: 0.9375rem 0 0 0;
    text-align: right;
    font-size: 0.75rem;
    color: #BFBFBF;
}

.input.alt {
    background: #232424;
    border: 0.0625rem solid #404040;
}

.input.alt:focus {
    border: 0.0625rem solid var(--link);
}

.upload-box {
    background: #FFF;
    border: 0.0625rem dashed var(--border-color);
    border-radius: 0.625rem;
    min-height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    position: relative;
    text-align: center;
}

.upload-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
}

.upload-box p {
    font-size: 0.75rem;
    color: #BFBFBF;
}

.modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.overview-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 0.9375rem;
}

.overview-left {
    width: 20.75rem;
    background: #232424;
    border: 0.0625rem solid #404040;
    border-radius: 1rem;
    padding: 0.75rem;
}

.overview-right {
    width: calc(100% - 20.75rem);
    padding-left: 0.9375rem;
}

.overview-box {
    border: 0.0625rem solid #404040;
    background: #232424;
    padding: 0.9375rem;
    border-radius: 0.5rem;
}

.label.sm {
    font-size: 0.625rem;
    text-transform: uppercase;
}

.input.sm {
    padding: 0.4375rem 0.9375rem;
    font-size: 0.75rem;
}

.input.dark {
    background: #171717;
}

.as-ratio {
    display: flex;
    align-items: center;
    gap: 0 0.3125rem;
}

.ratio-single {
    flex: 1;
    background: #151616;
    border: 0.0625rem solid #404040;
    border-radius: 0.375rem;
    height: 2.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ratio-single input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}

.ratio-single span {
    font-size: 0.6875rem;
}

.ratio-single:has(input:checked) {
    background: #FFF;
    color: #000;
}

.drop-2 .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--font-color);
    border: 0.0625rem solid #404040 !important;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.3125rem;
}

.drop-2 .dropdown-toggle:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.drop-2 .dropdown-toggle span {
    font-size: 0.75rem;
    font-weight: 500;
}

.drop-2 .dropdown-toggle::after {
    display: none;
}

.icon {
    width: 3.1875rem;
    height: 3.1875rem;
    border-radius: 0.5rem;
    background: #151616;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon img {
    max-width: 1.75rem;
}

.drop-2 .dropdown-menu {
    width: 23.75rem;
    border-radius: 1rem;
    border: 0.0625rem solid #404040;
    background: #171717;
    padding: 1.25rem;
    left: 100% !important;
    margin: -4rem 0 0 0;
}

.video-style {
    color: var(--font-color);
}

.video-style h5 {
    font-size: 0.875rem;
}

.video-style h6 {
    font-size: 0.625rem;
    text-transform: uppercase;
}

.video-style-list ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25rem;
}

.video-style-list ul li {
    width: 25%;
    padding: 0 0.25rem;
    margin-top: 0.5rem;
}

.video-style-img {
    height: 4.75rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #404040;
    overflow: hidden;
    margin-bottom: 0.3125rem;
}

.video-style-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-style-single p {
    font-size: 0.625rem;
    font-weight: 500;
    text-align: center;
}

.video-style-single {
    cursor: pointer;
}

.overview-left input,
.overview-left textarea {
    font-size: 0.75rem;
}


// 
.nav-1 {
    gap: 0.3125rem;

    .nav-item {
        flex: auto;
    }

    .nav-link {
        padding: 0.5rem 1.5rem;
        border-radius: 0.375rem;
        color: #151616;
        font-size: 1.0625rem;
        font-weight: 500;
        border: 0.0625rem solid var(--bg-color);
        background: #C4C9C9;
        text-align: center;

        &.active {
            border: 0.0625rem solid var(--border-color);
            background: linear-gradient(90deg, #18BD15 0%, #416D5E 100%);
            color: var(--white);
        }
    }
}

.temp {
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
    padding: 0.625rem;
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    &.active {
        border: 1.033px solid #18BD15;
        box-shadow: 0px 4px 8px 3px rgba(24, 189, 21, 0.25);
    }
}

.temp-img {
    width: 11.3125rem;
    height: 8.1875rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.temp-img img{ max-width: 3.125rem;}

.temp-txt {
    width: calc(100% - 11.3125rem);
    padding-left: 0.9375rem;

    h6 {
        font-size: 1.0625rem;
        color: #1A1A1A;
        font-weight: 600;
        width: calc(100% - 1.25rem);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    p {
        color: #838383;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0.375rem 0 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.slider-container {
    margin-right: -1.3125rem;
    position: relative;

    .slick-arrow {
        position: absolute;
        left: unset !important;
        right: 1.3125rem !important;
        top: -1.875rem;
        width: 1.5rem;
        height: 1.5rem;
        background: url('../images/arrow.svg') no-repeat !important;
        background-size: 100% auto;

        &.slick-prev {
            right: 2.8125rem !important;
            background: url('../images/arrow-2.svg') no-repeat !important;
            background-size: 100% auto;
        }

        &.slick-disabled {
            opacity: 0.2 !important;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        display: none !important;
    }

    .slick-disable {
        opacity: 0.5 !important;
    }
}

.page-wrap.expand {
    padding-left: 5.5rem;
    padding-top: 0;

    .side-nav {
        width: 5.5rem;
    }

    .side-link ul {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .side-link ul li a {
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .side-link ul li a div {
        display: none;
    }

    .header-logo {
        text-align: center;
    }

    .header-logo .logo-small {
        display: inline-block;
    }

    .header-logo .logo-full {
        display: none;
    }

    .page-container {
        padding: 0.9375rem;
    }

    .navButton div {
        display: none;
    }

    .navButton div+svg {
        display: none;
    }

    .navButton {
        padding: 0;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropItem {
        .collapse {
            padding: 0;

            div {
                display: none;
            }
        }
    }

    .kap_nav{ padding: 0 !important; width: auto !important;
        span{ width: 2.5rem; height: 2.5rem; display: flex; align-items: center; justify-content: center;}
        span div{ display: none;}
        span + span{ display: none;}
    }
}



.builder-header { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.0625rem solid var(--border-color);
    margin: 0 -0.9375rem;
    padding: 0 0.9375rem;
    padding-bottom: 0.9375rem;

    .site-link {
        font-size: 0.6875rem;
    }
}

.builder-header-left {
    display: flex;
    align-items: center;
    gap: 0 0.3125rem;

    .link {
        cursor: pointer;

        svg {
            font-size: 1.25rem;
        } 
    }

    h6.clips{ overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; max-width: 25rem;}
}

.builder-header-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 1rem;

    a {
        font-size: 0.6875rem;
        border-radius: 0.5rem;
        padding: 0.55rem 1.25rem;
        background: #C4C9C9;
        border: none;
        font-weight: 600;
        color: #151616;
        text-transform: uppercase;

        &.active,
        &:hover {
            background: linear-gradient(90deg, #18BD15 0%, #416D5E 100%);
            color: var(--white);
        }
    }

    .nav {
        display: flex;
        align-items: center;
        gap: 0 1rem;
    }
}

.builder-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0.9375rem;
}

.builder-area.left-align {
    justify-content: flex-start;
    gap: 0 15px;
    flex-wrap: nowrap;
}

.builder-left {
    position: relative;
    width: 28.125rem;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
    padding: 1.25rem;
    max-height: calc(100vh - 5.9375rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.builder-right {
    width: 17.5rem;
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
    padding: 1rem;
    max-height: calc(100vh - 5.9375rem);
    overflow-x: hidden;
    overflow-y: auto;

    .input {
        font-size: 0.875rem;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }

    .label {
        margin: 0;
        padding: 0 0 0.1875rem 0;
    }
}

.builder-mid {
    width: calc(100% - 48.125rem);
    height: calc(100vh - 5.9375rem);
    border-radius: 0.75rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
    padding: 1.25rem;
    overflow: auto;
}

.radio-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
}

.radio-group-single {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    position: relative;

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
        z-index: 2;
        opacity: 0;
    }

    .radio-box {
        width: 1.5rem;
        height: 1.5rem;
        border: 0.125rem solid var(--border-color);
        border-radius: 100%;
        position: relative;
    }
}

.radio-group-single:has(input:checked) .radio-box {
    border: 0.125rem solid var(--link);
}

.radio-group-single:has(input:checked) .radio-box::after {
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    background: var(--link);
    border-radius: 3.125rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.card-box {
    padding: 0.625rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
}

.layout-elements {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -0.3125rem;
    }

    li {
        width: 50%;
        padding: 0 0.3125rem;
        margin-top: 0.625rem;
    }
}

.layout-elements-single {
    height: 7.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem dashed var(--border-color);
    background: #FAFAFA;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.layout-elements-single:hover {
    border: 0.0625rem dashed var(--link);
}

.layout-elements-single p {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 600;
    padding-top: 0.375rem;
}

.layout-elements-single svg {
    font-size: 1.875rem;
}

// 


// 
.gen-card {
    border-radius: 0.625rem;
    border: 0.0625rem solid var(--border-color);
    text-align: center;
    padding: 1.25rem;

    p {
        color: rgba(26, 26, 26, 0.50);
        line-height: 1.125rem;
    }
}

.gen-icon {
    width: 3.125rem;
    height: 3.125rem;
    background: rgba(24, 189, 21, 0.10);
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--link);

    svg {
        font-size: 1.625rem;
    }
}

.search-temp {
    display: flex;
    align-items: center;
    border: 0.0625rem solid #CCC;
    border-radius: 0.5rem;
    overflow: hidden;

    input {
        border: none;
        background: none;
        padding: 0 1.125rem;
    }

    select {
        border: none;
        background: none;
        padding: 0 1.125rem;
        border-left: 0.0625rem solid var(--border-color);
    }

    .site-link {
        border-radius: 0;
    }
}

.px-12 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
}

.px-12 [class*='col-'] {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}

.temp.sm {
    .temp-img {
        width: 5rem;
        height: 5rem;
    }

    .temp-txt {
        width: calc(100% - 5rem);
    }
}

.upField {
    border-radius: 0.5rem;
    border: 0.0625rem dashed var(--border-color);
    background: #FAFAFA;
    position: relative;
    padding: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 4;
        cursor: pointer;
        opacity: 0;
    }
}

.upField-img {
    width: 6.5625rem;
    height: 6.5625rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid #C0C0C0;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 4.5rem;
    }
}

// 

// 
.prompt-var {
    font-size: 0.75rem;

    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.375rem;
        color: #838383;
    }

    li {
        border-radius: 0.25rem;
        border: 0.0625rem solid #C0C0C0;
        background: #F5F5F5;
        padding: 0.125rem 0.5rem;
        color: #333;
        cursor: pointer;

        &.active,
        &:hover {
            background: rgba(24, 189, 21, 0.10)
        }
    }
}

.drag-icon {
    cursor: pointer;

    svg {
        font-size: 1.375rem;
    }
}

.drag-element {
    margin-top: 1.375rem;

    &:hover {
        .drag-cont {
            border: 0.0625rem solid #C0C0C0;
            background: rgba(24, 189, 21, 0.10);
        }

        .drag-action {
            opacity: 1;
        }
    }
}

.drag-cont {
    border-radius: 0.375rem;
    border: 0.0625rem solid var(--white);
    padding: 0.75rem 1rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        left: 0;
        bottom: -0.75rem;
        background: linear-gradient(90deg, rgba(192, 192, 192, 0.00) 0%, #C0C0C0 25%, #C0C0C0 75%, rgba(192, 192, 192, 0.00) 100%);
    }

    .input {
        font-size: 0.875rem;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }
}

.form-info {
    color: rgba(51, 51, 51, 0.60);
    line-height: 1rem;
}

.drag-cont-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    overflow: hidden;

    .label {
        margin: 0;
        padding: 0;
    }
}

.drag-action {
    display: flex;
    align-items: center;
    gap: 0 0.375rem;
    opacity: 0;
}

.hideScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hideScrollBar::-webkit-scrollbar {
    display: none;
}

// 


// 
.design-left {
    width: 40.125rem;
}

.builder-block {
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
    padding: 1.5rem;

    h6 {
        font-size: 1rem;
    }

    p {
        font-size: 0.875rem;
        line-height: 0.875rem;
        ;
        color: rgba(51, 51, 51, 0.70);
        padding: 0.4rem 0 0 0;
    }
}

.builder-block-icon {
    width: 2.875rem;
    height: 2.875rem;
    border-radius: 0.5rem;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.design-block {
    border-radius: 0.5rem;
    background: #FFF;
    padding: 1.5rem;
}

.input.small {
    font-size: 0.875rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.colorBox {
    height: 4.0625rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 0.0625rem solid #C0C0C0;
}

.buttonStyle {
    display: flex;
    align-items: center;
    gap: 0 0.3125rem;
}

.buttonStyle button {
    flex: 1;
    text-align: center;
    height: 2.6875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 0.8125rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 2.125rem;
    height: 1.12rem;
}

.switch input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #BFBFBF;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 0.75rem;
    width: 0.75rem;
    left: 0.160rem;
    bottom: 0.125rem;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #18BD15;
}

input:checked+.slider:before {
    transform: translateX(1.0625rem);
}

.slider.round {
    border-radius: 2.125rem;
}

.slider.round:before {
    border-radius: 50%;
}

.scrollHeight {
    height: calc(100vh - 5.9375rem);
    overflow: auto;
}

.design-prev {
    width: 29.375rem;
    margin: 0 auto;
}

.design-prev-window {
    background: #E3E3E3;
    border-radius: 1rem;
    padding: 1.875rem 3.125rem;
}

.design-prev-window p {
    // color: rgba(0, 0, 0, 0.80);
    font-size: 0.875rem;
}

.design-prev-window .label {
    font-weight: 400;
}

.design-right {
    width: calc(100% - 40.125rem);
    display: flex;
    //align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.codeBlock {
    border-radius: 0.5rem;
    border: 0.0625rem solid #000;
    background: #282A36;
    padding: 0.75rem 1.5rem;
}

.cms-opt {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.625rem;
}

.cms-opt-single {
    width: 12.375rem;
    height: 5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #C0C0C0;
    background: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cms-opt-single img {
    max-height: 2.5rem;
}

.cms-opt-single:hover {
    box-shadow: 0rem 0.3125rem 0.875rem -0.375rem rgba(24, 189, 21, 0.50);
}

.or {
    position: relative;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0.625rem;
        width: 100%;
        height: 0.0625rem;
        background: var(--border-color);
    }

    span {
        background: var(--bg-color);
        display: inline-flex;
        position: relative;
        z-index: 2;
        padding: 0 1.125rem;
    }
}

.sc-links {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.375rem;

        img, svg {
            max-width: 2rem;
        }
    }
}

.builder-header-right{ display: flex; align-items: center; gap: 0 5px;}

.linkCopy {
    border-radius: 0.5rem;
    border: 0.0625rem solid #C0C0C0;
    background: #FAFAFA;
    box-shadow: 0rem 0.3125rem 0.875rem -0.375rem rgba(24, 189, 21, 0.50);
    padding: 0.75rem 1.5rem;
}

// 


// 
.themeTable tr th {
    border-bottom: 0.5rem solid var(--bg-color);
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
}

.themeTable tr td {
    border-bottom: 0.5rem solid var(--bg-color);
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    font-size: 0.875rem;
}

.themeTable tr td:first-child {
    padding-left: 1.5625rem;
}

.pageTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collection-card {
    border-radius: 0.5rem;
    background: #FFF;
    padding: 1.875rem;
    height: 100%;
}

.iconButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid #C0C0C0;
    background: #FAFAFA;
}

.iconButton svg {
    font-size: 1.125rem;
}

.iconButton.red,
.iconButton.red svg {
    color: #B92525;
}

.iconButton:hover {
    opacity: 0.6;
}

.collection-single {
    min-height: 6.25rem;
    height: 100%;
    border-radius: 0.5rem;
    background: #F5F5F5;
    padding: 0.9375rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h6 {
        font-size: 0.875rem;
    }

    p {
        font-size: 0.75rem;
        line-height: 0.875rem;
        opacity: 0.7;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 0.3125rem;
    }
}

.pageTitle-left {
    flex: 1;
}

.tempSingle {
    min-height: 11.9375rem;
    height: 100%;
    border-radius: 1rem;
    border: 0.0625rem solid #CCC;
    background: #FFF;
    padding: 1.25rem;
}

.tempSingle p {
    opacity: 0.5;
}

.actionBtn {
    border-radius: 0.375rem;
    border: 0.0625rem solid #CCC;
    background: #F6F6F6;
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
}

// 


// 
.portal-meta {
    display: flex;
    align-items: center;
    border-top: 0.0625rem solid #C0C0C0;
    margin: 0 -1.25rem;
    margin-top: 1.5rem;
    padding: 0 1.25rem;
    background: #FAFAFA;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.portal-meta-single {
    width: 33.33%;
    height: 5.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.portal-meta-single:nth-child(2) {
    border-left: 0.0625rem solid #C0C0C0;
    border-right: 0.0625rem solid #C0C0C0;
}

// 


// 
.sales-nav {
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 5;
    background: var(--bg-color);
    top: 3.75rem;

    .nav {
        position: relative;

        &::after {
            content: '';
            width: 100%;
            height: 0.25rem;
            background: #E6E6E6;
            position: absolute;
            left: 0;
            top: 0.875rem;
        }
    }

    .nav-item {
        margin-left: 5rem;

        &:first-child {
            margin-left: 0;
        }
    }

    .nav-link {
        padding: 0 !important;
        width: 2rem;
        height: 2rem;
        background: #E6E6E6;
        color: #808080;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        position: relative;
        z-index: 1;

        &.active {
            background: var(--link);
            color: var(--white);
        }
    }
}

.card-wrap {
    border-radius: 0.5rem;
    background: var(--white);
    padding: 1.25rem;
}

.up-prev {
    height: 5rem;
    border-radius: 0.25rem;
    border: 0.0625rem dashed #C0C0C0;
    background: #FAFAFA;
    padding: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    p {
        font-size: 0.75rem;
        opacity: 0.5;
    }
}

.prev-upload {
    height: 10rem;
    border-radius: 0.5rem;
    border: 0.0625rem dashed #C0C0C0;
    background: #FAFAFA;
    padding: 0.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;

    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        width: 100%;
        height: 100%;
    }
}

.iconButton.auto {
    width: auto;
    padding: 0 1.125rem;
    font-size: 0.9375rem;
}

// 


// 
.custom-radio-in {
    position: relative;
}

.custom-radio {
    input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
    }

    .radio-mark {
        border: 0.125rem solid var(--link);
        width: 1.25rem;
        height: 1.25rem;
        display: block;
        border-radius: 100%;
        position: relative;
    }

    input:checked~.radio-mark::after {
        content: '';
        position: absolute;
        left: 0.1875rem;
        top: 0.1875rem;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 100%;
        background: var(--link);
    }
}

.table.no-bdr {
    margin: 0;

    tr td {
        border: none !important;
    }
}

// 


.nav-style-4 {
    border: 0.0625rem solid #EAECF0;
    border-radius: 0.375rem;
    padding: 0.25rem;
}

.nav-style-4 .nav-item {
    flex: 1;
}

.nav-style-4 .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1.25rem;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #808080;
    border-radius: 0.25rem;
    gap: 0 0.5rem;
}

.nav-style-4 .nav-link svg {
    font-size: 1.25rem;
}

.nav-style-4 .nav-link.active {
    background: #E2FBE2;
    box-shadow: 0rem 0.0625rem 0.1875rem 0rem rgba(16, 24, 40, 0.10), 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.06);
    color: #18BD15;
}


.benefits-card {
    border-radius: 0.5rem;
    border: 0.0625rem solid #C0C0C0;
    background: #FAFAFA;
    font-size: 0.9rem;
    padding: 0.625rem 1.125rem;
}

.benefits-add {
    border-radius: 0.5rem;
    border: 0.0625rem dashed #C0C0C0;
    background: #FAFAFA;
    font-size: 0.9rem;
    padding: 0.625rem 1.125rem;
    cursor: pointer;
    color: rgba(27, 34, 38, 0.30);
}

.card-wrap.modalPrev {
    box-shadow: 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    padding: 0;
    overflow: hidden;
    max-width: 25rem;
    margin: 0 auto;
    border-radius: 1rem;
}

.modalPrev-bottom {
    border-top: 0.0625rem solid #EAECF0;
    background: #F9FAFB;
    padding: 1.5rem 0;
    text-align: center;
}

.modalPrev-cont {
    padding: 3.125rem 1.5rem;
    color: #475467;
}

.modalPrev-cont h5 {
    color: #18BD15;
    font-size: 1.25rem;
    font-weight: 600;
}

.modalPrev-cont h3 {
    color: #101828;
    font-size: 2.75rem;
    font-weight: 600;
}

.modalPrev-cont ul {
    padding-top: 0.9375rem;
}

.modalPrev-cont ul li {
    position: relative;
    margin-top: 0.625rem;
    padding: 0 0 0 2.3125rem;
}

.modalPrev-cont ul li::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../images/check.svg') no-repeat;
}

.modalPrev-icon {
    width: 3rem;
    height: 3rem;
    background: #EBFFEB;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #18BD15;
    font-size: 1.25rem;
}

.tool-card {
    border-radius: 0.8125rem;
    border: 0.0625rem solid #CCC;
    background: #FFF;
    padding: 2.5rem 1.5625rem;
    text-align: center;
    height: 100%;
}

.tool-card p {
    color: rgba(27, 34, 38, 0.50);
}

.tool-section {
    border-radius: 0.5rem;
    background: rgba(4, 255, 0, 0.10);
    padding: 0.625rem 0.9375rem;
    margin-top: 1.5625rem;
}

.tool-section ul {
    display: flex;
    align-items: center;
}

.tool-section ul li {
    width: 33.33%;
    text-align: center;
    background: url('../images/divide.png') right center no-repeat;
}

.tool-section ul li:last-child {
    background: none;
}

.tool-section ul li p {
    font-size: 0.8125rem;
}

.btn-option {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.3125rem;
    border: 0.0625rem solid #CCC;
    background: #F6F6F6;
    margin-top: 1.25rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
}

.btn-option .drop-style-1 button {
    // border-left: 0.0625rem solid #CCC !important;
    border-radius: 0 !important;
    padding: 0.6875rem 0.625rem !important;
}



// 
.titleFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.companyCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.companyCard-left {
    display: flex;
    align-items: center;
    gap: 0 12px;
}

.companyCard-right {
    display: flex;
    align-items: center;
    gap: 0 12px;
}

.companyCard-logo {
    width: 42px;
    height: 42px;
    overflow: hidden;
}

.companyCard-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.companyCard {
    border-radius: 0.5rem;
    border: 0.0625rem solid var(--border-color);
    background: var(--white);
    padding: 0.625rem;
}

.companyCard p {
    opacity: 0.5;
}

.link-btn {
    border: 1px solid var(--link);
    background: #FFF;
    padding: 4px 8px;
    border-radius: 50px;
}

.link-btn:hover {
    background: var(--link);
    color: var(--white);
}


.desBtn{ border: none; cursor: pointer; border-radius: 0.5rem; padding: 0.55rem 1.25rem; display: inline-flex; align-items: center; justify-content: center;} 

// 


/* Dashboard */
.hero-banner {
    height: 35.0625rem;
    //background: url('../src/images/hero-banner.jpg') top center no-repeat;
    background-size: cover;
    position: relative;
    color: #FFF;
  }
  
  .hero-banner-txt {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .hero-banner-txt .container,
  .hero-banner-txt .row {
    height: 100%;
  }
  
  .hero-banner-right {
    padding-left: 6.25rem;
  }
  
  .bannerLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.9);
    border-radius: 0.9375rem;
    border: 1px solid var(--button-1);
    height: 5rem;
    color: var(--button-1);
    font-weight: 600;
    padding: 0 0.625rem;
    padding-right: 1.25rem;
    text-transform: uppercase;
    margin: 1.875rem 0;
    backdrop-filter: blur(0.5rem); 
  }

  .bannerLink-right svg{ font-size: 2rem;}
  
  .bannerLink-left img {
    /* width: 3.75rem;
    margin-right: 0.75rem; */
  }
  
  .bannerLink:hover {
    color: #FFF;
    border: 1px solid var(--button-1);
    background: var(--button-1);
  }
  
  .hero-banner-left h2 {
    font-size: 3.75rem;
    font-weight: 800;
    -webkit-text-stroke: 1px #000;
    background-color: hsla(0, 0%, 100%, 0);
    color: hsla(0, 0%, 100%, 0);
  }
  
  .hero-banner-left h3 {
    font-size: 3.75rem;
    font-weight: 700;
    color: var(--button-1);
  }
  
  .hero-banner-left p {
    padding-right: 7.5625rem;
    padding-top: 0.625rem;
    font-size: 1rem;
    line-height: 1.75;
    color: #000;
  }
  
  .videoProject {
    padding: 1.875rem 0;
    padding-top: 4rem;
  }
  
  .videoProject-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .videoProject-top h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
  }
  
  .videoProject-top h2 span {
    color: var(--button-1);
  }
  
  .proView {
    display: flex;
    align-items: center;
    //color: #FFF;
    font-size: 1.875rem;
  }
  
  .proView span {
    display: inline-block;
    width: 2.1875rem;
    height: 2.1875rem;
    background: var(--button-1);
    color: #FFF;
    border-radius: 100%;
    text-align: center;
    font-size: 1.4375rem;
    margin-left: 0.9375rem;
  }
  
//   .proView:hover {
//     color: #C191FF;
//   }
  
  .videoProject-bottom {
    padding-top: 0rem;
  }
  
  .videoProject-single {
    background: #FFF;
    border-radius: 0.9375rem;
    padding: 0.625rem;
    border: 1px solid #efefef;
    margin-top: 1.875rem;
  }
  
  .videoProject-single-top {
    height: 14.375rem;
    position: relative;
    overflow: hidden;
    border-radius: 0.9375rem;
  }
  
  .vidThumb {
    height: 14.375rem;
    width: 100%;
    object-fit: cover;
  }
  
  .videoProject-single-bottom {
    display: flex;
    align-items: center;
    // color: #FFF;
    padding: 0.5rem 0;
    padding-top: 1.25rem;
  }
  
  .videoProject-single-bottom-desc {
    font-size: 0.8125rem;
    line-height: 1.125rem;
    padding-left: 0.9375rem;
  }
  
  .videoProject-single-bottom-desc span {
    color: #fff;
  }
  
  .playBtn {
    width: 2.25rem;
    height: 2.25rem;
    background: #FFF;
    border-radius: 100%;
    text-align: center;
    color: #925FEB;
    line-height: 2.25rem;
  }
  
  .playBtn .fa {
    display: inline-block;
    transform: translateX(0.125rem);
  }
  
  .vidHover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    /* padding: 0 1.875rem; */
    padding: 0 5px;
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    transition: all 0.5s ease;
  }
  
  .vidHover-single {
    margin: 0 0.3125rem;
  }
  
  .vidHover-single span {
    display: block;
    text-align: center;
  }
  
  .vidHover-icon {
    /* width: 3.125rem;
    height: 3.125rem; */
    width: 36px;
    height: 36px;
    line-height: 3.25rem;
    border-radius: 100%;
    background: #FFF;
    color: #000;
    font-size: 1.375rem;
    margin: 0 auto;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
  
  .vidHover-single a {
    display: inline-block;
    text-align: center;
    color: #FFF;
    margin-right: 20px;
    margin-bottom: 5px;
  }
  
  .vidHover-single span+span {
    font-size: 0.8125rem;
    padding: 0.5rem 0 0 0;
    font-size: 11px;
  }
  
  .modalIcon_modal .modal-content {
    height: 750px;
  }
  
  .landing-items a {
    width: 55px !important;
    height: 55px !important;
  }
  
  .modalIcon_modal .landing-items {
    height: 560px;
    padding-right: 7px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 15px;
    padding-bottom: 20px;
  }
  
  .landing-items a i {
    font-size: 30px;
  }
  
  .img-search-scroll.ka {
    height: 450px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 15px;
    margin-top: 10px;
  }
  
  /* .inp-small.ka::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border: 5px solid transparent;
    border-image: linear-gradient(90deg, #AF00FF 0%, #001EFF 100%);
    border-image-slice: 1;
    border-radius: 8px;
  } */
  
  .upload-icons i {
    cursor: pointer;
  }
  
  .vidHover-single span i {
    font-size: 15px;
  }
  
  .vidHover-single a:hover .vidHover-icon {
    color: #9024F3;
  }
  
  .videoProject-single:hover .vidHover {
    opacity: 1;
    visibility: visible;
  }
  
  .videoProject-single-awating {
    height: 14.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-weight: 700;
    font-style: italic;
    background: #e512c9;
    background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
  }
  
  .vidiconAll {
    display: flex;
    align-items: center;
    padding-top: 1.5625rem;
  }
  
  .vidHover p {
    font-size: 0.875rem;
    // color: #fff;
  }
  
  .featureSec {
    padding-top: 5rem;
  }
  
  .featureSec-single {
    // color: #FFF;
    margin-bottom: 6.25rem;
  }
  
  .featureSec-single:nth-child(even) [class*='col-'] {
    order: 2;
  }
  
  .featureSec-single:nth-child(even) [class*='col-']+[class*='col-'] {
    order: 1;
  }
  
  .featureSec-single:nth-child(even) .featureSec-left {
    padding-right: 0;
    padding-left: 6.25rem;
  }
  
  .featureSec-left {
    padding-right: 6.25rem;
  }

  .featureSec-left h2{ font-size: 1.9rem; font-weight: 600;}
  
  .featureSec-single h6 {
    font-family: 'Poppins', sans-serif;
    color: #7B7B7B;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
  }
  
  .featureSec-single h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
  
  .featureSec-single p {
    padding-top: 1.25rem;
  }
  
  .textL {
    position: relative;
    padding: 0.625rem 0;
    padding-left: 0.9375rem;
    font-weight: 600;
    font-style: italic;
    margin-top: 1.25rem;
    font-size: 0.9375rem;
  }
  
  .textL::after {
    content: '';
    width: 0.1875rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #e512c9;
    background: -moz-linear-gradient(top, #e512c9 0%, #5d00ff 100%);
    background: -webkit-linear-gradient(top, #e512c9 0%, #5d00ff 100%);
    background: linear-gradient(to bottom, #e512c9 0%, #5d00ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=0);
  }
  
  .textL span {
    color: #B48AFF;
  }
  
  .featureSec-right {
    text-align: right;
  }

  .featureSec-right img{ max-width: 30rem;}
  
  .featureSec-single:nth-child(even) .featureSec-right {
    text-align: left;
  }
  
  .lessonLink {
    background: #ededed;
    background-size: cover;
    padding: 2rem 0;
  }
  
  .demoLink {
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    padding: 0.875rem 1.875rem;
    border-radius: 3.125rem;
    margin-top: 1.875rem;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    background: #e512c9;
    background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
  }
  
  .demoLink-Ai-search {
    display: inline-block;
    min-width: 9.375rem;
    color: #e512c9;
    border-radius: 0.3125rem;
    font-weight: 600;
    padding: 0.875rem 1.875rem;
    margin-top: 1.875rem;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    background: #fff;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
  }
  
  .demoLink-topic {
    min-width: 19.6875rem;
    border-radius: 0.3125rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    cursor: pointer;
    border: none;
    color: #fff;
    transition: all 0.5s ease;
    background: #e512c9;
    background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
  }
  
  .create-or-line span {
    overflow: hidden;
    text-align: center;
  }
  
  .create-or-line span::before,
  .create-or-line span::after {
    background-color: #fff;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 47.5%;
  }
  
  .create-or-line span::before {
    right: 0.5em;
    margin-left: -47.5%;
  }
  
  .create-or-line span::after {
    left: 0.5em;
    margin-right: -47.5%;
  }
  
  .demoLink:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
  
  .demoLink .fa {
    margin-right: 0.625rem;
  }
  
  .demoLink:hover {
    color: #FFF;
  }
  
  .lessonLink-right {
    // color: #FFF;
    padding-left: 3.125rem;
  }
  
  .lessonLink-right h6 {
    font-family: 'Poppins', sans-serif;
    // color: #FFF;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.375rem;
  }
  
  .lessonLink-right h6 span {
    color: var(--button-1);
  }
  
  .lessonLink-right h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding-top: 0.5rem;
  }
  
  .lessonLink-right p {
    padding: 1.25rem 0 0 0;
  }

  .support-video-thumb.iconx{ height: 10rem; display: flex; align-items: center; justify-content: center; border-bottom: 0.0625rem solid var(--border-color);}
  .support-video-thumb.iconx img{ max-width: 4rem;}
  
  .btn.btn-primary {
    display: inline-block;
    color: #FFF;
    font-weight: 600;
    padding: 0.875rem 1.875rem;
    border-radius: 3.125rem;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    background: #e512c9;
    background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
  }
  
  .VideoModal .modal-dialog {
    max-width: 62.5rem;
  }
  
  .VideoModal .modal-content {
    background: #000;
    border-radius: 0.9375rem;
  }
  
  .modalVidWrap {
    width: 100%;
    padding-top: 66.25%;
    position: relative;
  }
  
  .modalVid iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .vidClose {
    position: absolute;
    right: 0;
    top: -3rem;
    cursor: pointer;
    z-index: 1 !important; 
  }
  
  .font-adjust {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .font-adjust>div {
    display: flex;
    align-items: center;
    margin-left: 0.9375rem;
  }
  
  .font-adjust>div input {
    background: #000;
    border: none;
    color: #FFF;
    padding: 0.625rem !important;
    height: 2.25rem;
  }
  
  .font-adjust>div input:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
  
  .font-adjust label {
    margin: 0 !important;
    padding: 0 0.5rem 0 0 !important;
  }
  
  .font-adjust .picker {
    transform: none !important;
  }
  
  .research-btn {
    min-width: 11.875rem;
    border-radius: 0.3125rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: inline-block;
    color: #FFF;
    font-weight: 400;
    font-size: 0.875rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: bold;
    cursor: pointer;
    border: none;
    transition: all 0.5s ease;
    background: #e512c9;
    background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
  }
  
  .cont-research-btn .btn.btn-primary {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  
  .back-Button a {
    background: #e512c9;
    border-radius: 3.125rem !important;
    padding: 0.625rem 1.875rem !important;
    background: -moz-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: -webkit-linear-gradient(left, #e512c9 0%, #5d00ff 100%);
    background: linear-gradient(to right, #e512c9 0%, #5d00ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e512c9', endColorstr='#5d00ff', GradientType=1);
  }
  
  .btn-wrapper .btn-2 {
    font: 700 1rem/1rem "Montserrat", sans-serif;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(155 51 233 / 35%);
    font-size: 1rem;
    font-weight: 700;
    // color: #8133db;
    padding: 0.9375rem 0;
  
  }
  
  .btn-wrapper .btn-2:hover,
  .btn-3 {
    background-color: #8133db;
    color: #fff;
  }
  
  .btn-3:hover {
    background-color: #fff;
    color: #8133db !important;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(155 51 233 / 35%);
    border: 0.1125rem solid #8133db;
  }
  
  
  
  .delicon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    opacity: 0;
    visibility: hidden;
  }
  
  .delicon svg {
    margin-left: 0.3125rem;
  }
  
  .editorReel-single:hover .delicon {
    opacity: 1;
    visibility: visible;
  }
  
  .proImage-holder .custom-file {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
  }
  
  .proImage-holder .custom-file input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .proImage-holder:hover .custom-file {
    opacity: 1;
    visibility: visible;
  }
  
  .custom-file svg {
    font-size: 1.375rem;
  }
  
  .tableWrap .table-dark tr td {
    text-align: center;
  }
  
  .upgrade-para ol {
    padding-left: 0.9375rem;
  }
  
  .upgrade-para ol li {
    list-style-type: disc;
    margin-top: 0.5rem;
  }


  .hero-video {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-bottom: 56.25%;
}

.hero-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-9.375rem);
}


.hero-banner::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,#f6f6f6 100%);
    background: #ededed;
}

.hero-banner-txt {
    z-index: 1;
}

.hero-banner {
    overflow: hidden;
}

.bannerLink-left {
    display: flex;
    align-items: center;
    gap: 0 0.9375rem;
}


.bannerLink-left {
    display: flex;
    align-items: center;
    gap: 0 0.9375rem;
  }
  
  .bannerLink-left-icon {
    width: 4.125rem;
    height: 4.125rem;
    //background-image: url('../src/images/icon-bg-1.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: var(--button-1);
    border-radius: 0.8rem;
  }
  
  .icon-second {
    //background-image: url('../src/images/icon-bg-2.png'); 
  }
  
  .icon-third {
    //background-image: url('../src/images/icon-bg-3.png');
  }
  
  .bannerLink-left-icon svg {
    font-size: 1.875rem;
  }


  .videoProject-single.ks {
    margin-top: 0 !important;
}


.dashboard-card-bg {
    // background-image: url(http://localhost:3001/static/media/vid-bg.53c7543….png) !important;
    background-repeat: no-repeat;
    background-size: cover;
}

.video-card-wrapper-1 {
    background: var(--button-1);
}

.video-card-wrapper-1 {
    width: 100%;
    max-width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    //background: linear-gradient(190deg, #e512c9 0%, #5d00ff 100%);
    position: relative;
}

  
  /* Dashboard */


.design-prev-img{ text-align: center;}
.design-prev-img img{ max-width: 7rem;}


@media (max-width: 1799px) {
    .nav-1 .nav-item {
        flex: auto;
    }

    .layout-elements-single {
        height: 6.5rem;
    }

    .page-container.alt .container{ padding: 0 40px;}
    .hero-banner-right{ padding-left: 0;} 
}

@media (max-width: 1199px) {
    .page-container {
        padding: 15px;
    }

    .side-nav {
        width: 260px;
        left: -260px;
        transition: all 0.5s ease;
        top: 62px; 
    }

    .side-nav .header-logo{ display: none;}

    .App:has(.navToggle.active) .side-nav{ left: 0;}

    .page-wrap.expand {
        padding-left: 0;
    }

    .builder-area {
        flex-wrap: wrap;
    }

    .builder-mid {
        order: 1;
        width: 100%;
        max-width: 100%;
        height: auto;
        height: auto;
        max-height: 48vh;
    }

    .builder-left {
        order: 2;
        max-height: 37vh;
        margin-top: 20px;
        width: 360px;
    }

    .builder-right {
        order: 3;
        max-height: 37vh;
        margin-top: 20px;
        width: calc(100% - 380px);
    }

    .layout-elements li {
        width: 25%;
    }

    .slider-container {
        margin: 0;
    }

    .page-container.alt{ padding: 0;}
}

@media (max-width: 1023px) {
    .layout-elements li {
        width: 33.33%;
    }

    .builder-header {
        flex-wrap: wrap;
        gap: 10px 0;
    }

    .builder-header-mid {
        flex-wrap: wrap;
        gap: 10px;
    }

    .builder-header-mid a {
        padding-left: 11px;
        padding-right: 11px;
    }

    .builder-header-mid .nav {
        gap: 0 5px;
    }

    .design-left {
        width: 100%;
        order: 2;
    }

    .design-right {
        width: 100%;
        order: 1;
        padding: 30px 0;
    }

    .scrollHeight {
        height: auto;
    }

    .cms-opt {
        margin-top: 30px;
    }

    .featureSec-right img{ max-width: 100%;} 
    .hero-banner-left h2{ font-size: 40px;}
    .hero-banner-left h3{ font-size: 30px;}
    .page-container.alt .container{ padding-left: 20px; padding-right: 20px;}
    .featureSec-left{ padding-right: 0;}
    .featureSec-single:nth-child(even) .featureSec-left{ padding-left: 0;}
    .hero-banner-left p{ padding-right: 15px;}
    .bannerLink{ margin: 10px 0;}
}

@media (max-width: 767px) {
    .page-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .builder-header-mid {
        background: var(--white);
        padding: 15px;
        border-radius: 5px;
    }

    .builder-left,
    .builder-right {
        width: 100%;
    }

    .builder-header {
        flex-direction: column;
    }

    .temp-img {
        width: 50px;
        height: 50px;
    }

    .temp-txt {
        width: calc(100% - 50px);
    }

    .temp {
        align-items: flex-start;
    }

    .page-wrap {
        padding-top: 86px;
    }

    .builder-header-left {
        width: 100%;
        justify-content: space-between;
    }

    .builder-header-mid .nav {
        gap: 5px;
        justify-content: center;
    }

    .builder-mid,
    .builder-right,
    .builder-left {
        max-height: unset;
    }

    .builder-area.left-align {
        flex-wrap: wrap;
    }

    .linkCopy {
        margin-top: 30px;
    }

    .cms-opt-single {
        width: 47%;
    }

    .cms-opt-single img {
        max-height: 20px;
    }

    .pageTitle {
        flex-wrap: wrap;
    }

    .pageTitle-left {
        width: 100%;
        flex: unset;
    }

    .pageTitle-right {
        width: 100%;
        padding-top: 15px;
    }

    .collection-card {
        padding: 15px;
    }

    .page-container{ padding: 0;}
    .page-container.alt .container{ padding-left: 15px; padding-right: 15px;}

    .bannerLink-left-icon{ width: 3.125rem; height: 3.125rem;}
    .bannerLink{ height: 4.2rem;}

    .page-container.alt{ padding: 33px 0 0 0;}

    .featureSec-single .col-12{ order: 2 !important;}
    .featureSec-single .col-12 + .col-12{ order: 1 !important;}
    .featureSec-left{ padding-top: 30px !important;}
    .hero-banner-left p{ line-height: 20px;}
}