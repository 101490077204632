body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-account-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:#18bd15;
}

.hide-social {
  display: none !important;
}
.themelink:hover {
  color: #000 !important;
}

.h-1\/2{
  height: 50% !important;
}
.w-full{
  width: 100% !important;
}

.absolute {
  position: absolute !important;
}

.modalVidFb {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./images/Ai_img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 37.5rem;
}
