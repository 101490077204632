body.light {
  --bg-color: #F6F6F6;
  --font-color: #1A1A1A;
  --primary-color: #0D0C14;
  --border-color: #CCCCCC;
  --button-1: #18BD15;
  --button-2: #18BD15;
  --link: #18BD15;

  --white: #FFFFFF;
  --black: #000000;

  --color-1: #333333;
  --color-2: #F7F7F7;
  --color-3: #18BD15;
  --color-4: #C0C0C0;
  --color-5: #808080;

}

@import url('https://fonts.googleapis.com/css2?family=Host+Grotesk:ital,wght@0,300..800;1,300..800&display=swap');

/* Reset CSS */
* {
  padding: 0rem;
  margin: 0rem;
}

*:focus {
  outline: 0;
}

html {
  font-size: 16px !important;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 0.9375rem !important;
  margin: 0;
  padding: 0;
  font-family: "Host Grotesk", sans-serif;
  background: transparent;
  color: var(--font-color);
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

a,
a:link {
  text-decoration: none;
  outline: none;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--link);
}

a:hover {
  text-decoration: none;
}

a:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

::-webkit-input-placeholder {
  opacity: 0.9;
  color: #000;
}

:-moz-placeholder {
  opacity: 0.9;
  color: #000;
}

::-moz-placeholder {
  opacity: 0.9;
  color: #000;
}

:-ms-input-placeholder {
  opacity: 0.9;
  color: #000;
}

/* Reset CSS */


.colorGrey {
  color: var(--color-5);
}

hr {
  border-top: 1px solid #E6E6E6 !important;
  opacity: 1 !important;
}

.container {
  max-width: 71.25rem !important;
}

.site-link {
  border-radius: 0.5rem;
  background: linear-gradient(90deg, var(--button-1) 0%, var(--button-2) 100%);
  color: var(--white);
  border: none;
  padding: 0.55rem 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.site-link span {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
}

.site-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: -100%;
  top: 0;
  background: #18b415;
  transition: all 0.5s ease;
}

.site-link:hover::before {
  left: 0;
}

.site-link.grey {
  border: 0.0625rem solid #CCC;
  background: #F6F6F6;
  color: #1E1E1E;
}

.site-link.grey::before {
  background: #e6e6e6;
}

.site-link.small {
  font-size: 0.75rem;
}

.site-link svg {
  font-size: 1.25rem;
}

.site-link.full {
  width: 100%;
}

.site-link.lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.site-link.black {
  background: var(--bg-color);
}

.site-link.dark {
  background: var(--bg-color);
}

.site-link.dark span {
  background: linear-gradient(to right, var(--button-1), var(--button-2));
  color: transparent;
  background-clip: text;
}

.site-link.dark:hover span {
  color: var(--font-color);
}

.site-link.alt {
  border-radius: 6px;
  border: 1px solid #CCC;
  background: #F6F6F6;
  font-size: 14px;
  color: #333;
  padding: 8px 24px;
}

.site-link.alt:hover {
  color: #333;
}

.site-link.alt::before {
  background: #e7e7e7;
}

.site-link.simple {
  background: none;
}

.site-link.simple::before {
  background: var(--primary-color);
}

.site-link.sm {
  padding: 0.3125rem 0.625rem;
  gap: 0 0.1875rem;
  font-size: 0.75rem;
}

.site-link.sm span {
  gap: 0 0.1875rem;
}

.site-link.smaller {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 4px;
}

.site-link.red {
  background: #B81E1C;
}

.site-link.red::before {
  background: #961917;
}

.site-link.orange {
  background: #f47730;
}

.site-link.orange::before {
  background: #f76815;
}

.site-link.white {
  background: #FFF;
  border: 1px solid var(--border-color);
  color: #333333 !important;
}

.site-link.white::before {
  background: #f7f7f7;
}

.gradient-txt {
  background: linear-gradient(to right, var(--button-1), var(--button-2));
  color: transparent;
  background-clip: text;
  display: inline-block;
}


.fs-7 {
  font-size: 0.8125rem;
}




.demoLink-2,
.btn-2,
.btn-3,
.demoLink {
  border-radius: 0.5rem;
  background: linear-gradient(90deg, var(--button-1) 0%, var(--button-2) 100%);
  color: var(--white);
  border: none;
  padding: 0.55rem 1.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
}

.text-white {
  color: var(--font-color) !important;
}
.white-list-item ul{
 list-style: disc!important;
 padding-left: 25px;
}

@media (max-width: 1599px) {
  html {
    font-size: 14px !important;
  }
}